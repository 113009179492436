import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Button } from "antd";
import Papa from "papaparse";
import "../App.css";
import dataService from "../network/api/services/dataService";
import { toast } from "react-toastify";
import BackDropLoader from "../components/BackDropLoader";
import { Box } from "@chakra-ui/react";

const generateCSVData = (data) => {
  const csvData = [];
  csvData.push(["SNo", "Name", "Email", "Mobile", "Address"]);
  data.forEach((customer) => {
    csvData.push([
      customer.key,
      customer.name,
      customer.email,
      customer.mobile,
      customer.address,
    ]);
  });
  return Papa.unparse(csvData);
};

const Customers = () => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [isCustomerDetailsModalVisible, setIsCustomerDetailsModalVisible] =
    useState(false);
  const [customerList, setcustomerList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const openCustomerDetailsModal = (record) => {
    setCustomerDetails(record);
    setIsCustomerDetailsModalVisible(true);
  };

  const closeCustomerDetailsModal = () => {
    setIsCustomerDetailsModalVisible(false);
  };

  const handleExportToCSV = () => {
    const csvData = generateCSVData(data1);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "customers.csv");
    link.click();
  };

  const data1 = customerList.map((customer, index) => {
    const profile = customer.profile || {}; // Ensure profile object exists
    return {
      key: index + 1,
      name: `${profile.firstName || "N/A"} ${profile.lastName || ""}`,
      email: customer.email || "",
      mobile: customer.phoneNumber || "Not Provided",
      address:
        customer.addresses && customer.addresses.length > 0
          ? customer.addresses[0]
          : "",
    };
  });

  const getCustomerList = async () => {
    setIsRefreshLoading(true);
    try {
      const response = await dataService.getCustomers();
      setcustomerList(response.data);
    } catch (error) {
      console.error("Error fetching customer list:", error);
      toast.error("Error fetching customer list");
    } finally {
      setIsRefreshLoading(false);
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const columns = [
    {
      name: "SL.No.",
      selector: "key",
      className: "column-class-sl-no",
      width: "80px",
    },
    {
      name: "Name",
      selector: "name",
      className: "column-class-name",
      width: "180px",
    },
    {
      name: "Email",
      selector: "email",
      className: "column-class-email",
      width: "220px",
    },
    {
      name: "Mobile",
      selector: "mobile",
      className: "column-class-mobile",
      width: "150px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <div>
          <Button type="primary" onClick={() => openCustomerDetailsModal(row)}>
            View
          </Button>
        </div>
      ),
      className: "column-class-action",
    },
  ];

  return (
    <div>
      {isRefreshLoading && <BackDropLoader />}

      <h3 className="mb-4 title">Customers</h3>
      {isLoading && (
        <Button type="primary" onClick={handleExportToCSV}>
          Download data in excel sheet
        </Button>
      )}
      <Box mt={4} mb={4}>
        <DataTable
          columns={columns}
          data={data1}
          onRowClicked={(row) => openCustomerDetailsModal(row)}
          pagination
          fixedHeader
          responsive
          highlightOnHover

        />
      </Box>
      <Modal
        open={isCustomerDetailsModalVisible}
        onCancel={closeCustomerDetailsModal}
        footer={null}
      >
        {customerDetails && (
          <div className="customer-details-modal">
            <h2 style={{ marginBottom: "40px" }}>Customer Details</h2>
            <h5>SNo: {customerDetails.key}</h5>
            <h5>Name: {customerDetails.name}</h5>
            <h5>Email: {customerDetails.email}</h5>
            <h5>Mobile: {customerDetails.mobile || "Not Provided"}</h5>
            <button onClick={closeCustomerDetailsModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Customers;
