import React from "react";
import Lottie from "lottie-react";

import successAnimation from "../successAnimation.json";

const ResetPasswordSuccessPage = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1a1a1a",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "30%",
          width: "30%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie animationData={successAnimation} height={30} width={30} />
      </div>

      <div
        style={{
          textAlign: "center",
          margin: "2rem",
        }}
      >
        <h1>Success!</h1>
        <p>Your password has been successfully reset.</p>
        <p>
          You can now close this tab and login in the app with your new
          password.
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessPage;
