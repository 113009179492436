import React, { useState, useEffect } from "react";
import { Modal, Button, Drawer } from "antd";
import DataTable from "react-data-table-component";
import dataService from "../../network/api/services/dataService";
import AddCategories from "./AddCategories";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
} from "@chakra-ui/react";

function Categories() {
  const [isLoading, setIsLoading] = useState(true);
  const [newCategory, setNewCategory] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [file, setFile] = useState(null);
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <img
          src={row.image}
          alt={row.title}
          style={{ width: "60px", height: "60px" }}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Flex direction={{ base: "column", md: "row" }} gap={"10px"}>
          <Button type="primary" onClick={() => handleEditCategory(row)}>
            Edit
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteCategory(row)}
          >
            Delete
          </Button>
        </Flex>
      ),
    },
  ];

  const data = categoriesList?.map((order, ind) => ({
    key: order?._id,
    slno: ind + 1,
    name: order.name,
    image: order.image,
    createdat: new Date(order.createdAt).toLocaleString(),
    updatedat: new Date(order.updatedAt).toLocaleString(),
  }));

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const response = await dataService.getCategories();
      setCategoriesList(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, [newCategory]);

  const handleAddCategoryClick = () => {
    setSelectedCategory(null);
    setIsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setSelectedCategory(null);
    setFile(null);
  };

  const handleEditCategory = (record) => {
    setSelectedCategory(record);
    setIsDrawerVisible(true);
    setFile(record.image);
  };

  const handleDeleteCategory = (record) => {
    const id = record.key;
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          await dataService.deleteCategory(id);
          Modal.success({
            title: "Category deleted successfully",
          });
          getCategories();
        } catch (error) {
          console.error("Error deleting category:", error);
        }
      },
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={handleAddCategoryClick}>
          Add Category
        </Button>
      </div>

      <Box display={{ base: "none", md: "block" }}>
        <DataTable
          columns={columns}
          data={data.filter(
            (item) =>
              item.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
              item.image?.toLowerCase().includes(searchText?.toLowerCase())
          )}
          responsive
          noHeader
          pagination
          highlightOnHover
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          }
          customStyles={{
            rows: { style: { padding: "10px", fontSize: "15px" } },
            headCells: { style: { fontSize: "15px" } },
          }}
        />
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        {categoriesList?.map((category) => (
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            style={{ marginBottom: "10px" }}
            size={"xs"}
            padding={"10px"}
            key={category._id}
            alignItems={"center"}
          >
            <Box>
              <Image
                alt={category.name}
                height={"100px"}
                width={"70px"}
                objectFit={"cover"}
                src={category.image}
                marginX={{ base: "auto", sm: "10px" }}
              />
            </Box>

            <CardBody>
              <Heading size="lg">{category.name}</Heading>
            </CardBody>

            <CardFooter
              display={"flex"}
              alignItems={"center"}
              flexDirection={{ base: "row", sm: "column" }}
              justify={"space-around"}
              gap={"10px"}
            >
              <Button
                type="primary"
                onClick={() => handleEditCategory(category)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => handleDeleteCategory(category)}
              >
                Delete
              </Button>
            </CardFooter>
          </Card>
        ))}
      </Box>

      <Drawer
        title={selectedCategory ? "Edit Category" : "Add Category"}
        placement="right"
        onClose={handleCloseDrawer}
        open={isDrawerVisible}
        width={{ xs: 300, sm: 400, md: 500, lg: 600, xl: 700, xxl: 800 }}
      >
        <AddCategories
          setIsDrawerVisible={setIsDrawerVisible}
          isDrawerVisible={isDrawerVisible}
          setNewCategory={setNewCategory}
          newCategory={newCategory}
          onClose={handleCloseDrawer}
          selectedCategory={selectedCategory}
          setFile={setFile}
          file={file}
          getCategories={getCategories}
        />
      </Drawer>
    </div>
  );
}

export default Categories;
