export const SIGN_IN = "/vendors/sign-in";
export const FORGOT_PASSWORD = "/vendors/forgot-password";
export const PRODUCT_LIST = "/products/list-products";
export const PRODUCT_INFO = "/products/list-product";
export const CREATE_PRODUCT = "/products/create-product";
export const DELETE_PRODUCT = "/products/delete-product/";
export const UPDATE_PRODUCT = "/products/update-product/";
export const UPLOAD_BULK_PRODUCT = "/products/create-bulk-products";
export const SUGGESTION_PRODUCT = "/products/search-suggestions";
export const GET_SUGGESTED_PRODUCT = "/products/search-products";
export const ORDERS_LIST = "/vendors/get-orders";
export const CUSTOMERS = "/vendors/get-users";
export const UPDATE_ORDER = "/vendors/update-order";
export const GET_BANNERS = "/vendors/get-banners/";
export const GET_CATEGORIES = "/vendors/get-categories";
export const DELETE_CATEGORY = "/vendors/delete-category/";
export const UPLOAD_BANNER = "/vendors/upload-banner-image";
export const EDIT_BANNER = "/vendors/edit-banner";
export const UPLOAD_CATEGORY = "/vendors/upload-category-image";
export const UPLOAD_PRODUCT = "/vendors/upload-product-image";
export const ADD_CATEGORIES = "/vendors/add-category";
export const UPDATE_CATEGORY = "/vendors/edit-category";
export const DELETE_BANNER = "/vendors/delete-banner/";
export const ADD_BANNER = "/vendors/add-banner";
export const VERIFY_VENDOR_OTP = "/vendors/verify-vendor-otp";
export const RESET_VENDOR_PASSWORD = "/vendors/reset-password";
export const CREATE_DELIVERY_BOY ="/delivery/add-delivery-boy";
export const GET_ALL_DELIVERY_BOYS ="/delivery/get-vendor-delivery-boys"
export const GET_UNAPPROVED_DELIVERY_BOYS="/delivery/get-unapproved-delivery-boys"
export const APPROVE_DELIVERY_BOY ="/delivery/approve-vendor-delivery-boy"
export const ORDER_DETAILS = "/vendors/order-details/"
export const ASSIGN_DELIVERY_BOY_TO_ORDER = "/vendors/assign-delivery-boy-to-order"
export const GET_VENDOR_PROFILE ="/vendors/profile"
export const GET_DASHBOARD_INFO ="/vendors/dashboard-info"



// Admin Side End Points


export const GET_VENDORS = "/admin/get-vendors";
export const APPROVE_VENDOR = "/admin/approve-vendor";
export const GET_ALL_PRODUCTS = "/admin/get-all-products";
export const APPROVE_PRODUCTS ="/admin/approve-products"
export const GET_UNAPPROVED_PRODUCTS ="/admin/get-unapproved-products"
export const GET_VENDOR_PRODUCTS="/admin/get-vendor-product"
export const GET_ACCOUNT_DETAILS ="/admin/get-account-detail"
export const CREATE_COUPON ="/admin/create-coupon"
export const GET_COUPON ="/admin/get-all-coupons"
export const EDIT_COUPON ="/admin/edit-coupon"
export const DELETE_COUPON ="/admin/delete-coupon"
export const GET_UNAPPROVED_DELIVERY_BOYS_ADMIN = "/admin/get-unapproved-delivery-boy"
export const APPROVE_DELIVERY_BOY_ADMIN ="/admin/approve-delivery-boy"
export const CREATE_DELIVERY_BOY_ADMIN ="/admin/add-delivery-boy"
export const ALL_DELIVERY_BOYS ="/admin/all-delivery-boys" 



// USER SIDE API END POINTS 


export const VERIFY_USER_OTP = "/users/verify-user-otp";
export const RESET_USER_PASSWORD = "/users/reset-password";

