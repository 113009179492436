import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import authService from "../network/api/services/authService";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const response = await authService.forgotPassword({ email: values.email });

      console.log("Response Data:", response);

      message.success(response.message);     

      form.resetFields();

    } catch (error) {
      console.error("Error submitting forgot password form:", error);

      message.error("Failed to send password reset email. Please try again.");

    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}
    >
      <div
        className="bg-white rounded p-5"
        style={{
          border: "1px solid #d9d9d9",
          maxWidth: "500px",
          width: "95%",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          className="title"
          style={{ fontFamily: "sans-serif", marginBottom: "20px" }}
        >
          Forgot Password
        </h2>
        <p style={{ fontSize: "14px", color: "#888", marginBottom: "20px" }}>
          Enter your email to reset your password.
        </p>

        <Form
          form={form}
          name="forgot_password"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please enter your email address!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
