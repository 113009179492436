import React, { useState, useEffect } from "react";
import { Button, Input, Select, Upload, message, Form, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dataService from "../../network/api/services/dataService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadProductImage } from "../../formatter/ImageConverter";
import { imageTypeValid } from "../../formatter/ValidImageType";

const { TextArea } = Input;
const { Option } = Select;

const AddProduct = () => {
  const [form] = Form.useForm();
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: 0,
    brand: "",
    quantity: 0,
    costPrice: 0,
    sellingPrice: 0,
  });

  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [currentAction, setcurrentAction] = useState("");
  const [productId, setproductId] = useState("");
  const productData = location?.state?.productData;
  const navigate = useNavigate();
  const [deleteProduct, setdeleteProduct] = useState(false);
  const [productImages, setproductImages] = useState([]);
  const [categoryId, setcategoryId] = useState("");

  const customRequest = ({ file, onSuccess, onError }) => {
    handleImageChange(file, onSuccess, onError);
  };

  const handleImageChange = async (file, onSuccess, onError) => {
    console.log(file, "file", file.name);
    const imageType = imageTypeValid(file.name);

    if (imageType) {
      try {
        console.log("try");
        if (file.status !== "error") {
          const response = await uploadProductImage(file);
          if (response.code === 200) {
            const imageUrl = response.data;
            // setProduct({
            //   ...product,
            //   images: [...product.images, imageUrl],
            // });
            const newItem = {
              uid: String(Date.now()),
              name: "productImage" || "New Image",
              status: "productStatus" || "done",
              url: imageUrl,
            };
            setproductImages((prevList) => [...prevList, newItem]);
            toast.success(response.message);
            onSuccess();
          } else {
            message.error("Image upload failed");
            onError();
          }
        }
      } catch (error) {
        console.error("Error during image upload:", error);
        message.error("Image upload failed");
        onError();
      }
    } else {
      toast.error("Invalid file type.");
    }
  };

  const handleRemove = (file) => {
    const updatedFileList = productImages.filter(
      (item) => item.uid !== file.uid
    );
    setproductImages(updatedFileList);
    message.success(`${file.name} file removed successfully`);
  };

  const filterImagesOfProduct = (productImages) => {
    const imageArray = productImages.map((val) => {
      console.log(val.url, "values");
      return val.url;
    });
    return imageArray;
  };

  const handleSubmit = async (values) => {
    console.log(values, { images: product.images }, "hello", product);
    console.log(
      {
        ...values,
        images: productImages,
        category: categoryId,
      },
      "==>"
    );
    console.log(productImages, "productImages");
    const itemImages = filterImagesOfProduct(productImages);
    let response;
    try {
      // const response = await dataService.addProduct({
      //   ...values,
      //   images: product.images,
      // });

      if (typeof productData === "undefined") {
        console.log("add");
        response = await dataService.addProduct({
          ...values,
          images: itemImages,
          category: categoryId,
        });
      } else {
        response = await dataService.updateProduct(
          {
            ...values,
            images: itemImages,
            category: categoryId,
          },
          productId
        );
      }

      // if (response.code === 201 || response.code == 200 ) {
      toast.success(response.message);
      navigate("/vendor/list-product");
      // }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleSwitchChange = (checked) => {
    setdeleteProduct(checked);
  };

  const handleCategoryChange = (value, option) => {
    console.log(value, option);
    if (option && value) {
      setcategoryId(value);
    }
  };

  useEffect(() => {
    console.log(productData, "productData");
    if (typeof productData !== "undefined") {
      setproductId(productData?._id);
      // setdeleteProduct(!productData.isDeleted)
      setProduct({
        ...product,
        ...productData,
      });
      form.setFieldsValue({
        ...product,
        ...productData,
      });

      form.setFieldsValue({
        isDeleted: productData?.isDeleted,
        category: productData?.category[0]?.name,
      });
      setCategories(productData?.category);
      // setProduct({
      //   ...product,
      //   images: [...product.images]
      // });
      handleCategoryChange(
        productData?.category[0]?._id,
        productData?.category[0]
      );
      const images = productData.images;
      images.map((val, ind) => {
        let imageItem = {
          uid: String(Date.now()) + ind,
          name: "productImage" || "New Image",
          status: "productStatus" || "done",
          url: val,
        };
        setproductImages((prevList) => [...prevList, imageItem]);
      });

      setcurrentAction("Update Product");
    } else {
      setcurrentAction("Add Product");
      form.resetFields();
      form.setFieldsValue({
        name: "",
        description: "",
        price: 0,
        brand: "",
        quantity: 0,
        costPrice: 0,
        sellingPrice: 0,
        isDeleted: false,
      });
      setProduct({
        name: "",
        description: "",
        price: 0,
        brand: "",
        quantity: 0,
        images: [],
        costPrice: 0,
        sellingPrice: 0,
      });
      setproductImages([]);
    }
  }, [productData]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await dataService.getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <div className="container">
      <h1>{currentAction}</h1>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          name: product.name,
          description: product.description,
          price: product.price,
          brand: product.brand,
          quantity: product.quantity,
          costPrice: product.costPrice,
          sellingPrice: product.sellingPrice,
        }}
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter the product name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: "Please enter the product description" },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="MRP"
          name="price"
          rules={[
            { required: true, message: "Please enter the product price" },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[
            { required: true, message: "Please select the product category", },
          ]}
        >
          <Select
            onChange={(value, option) => handleCategoryChange(value, option)}
          >
            {categories.map((category) => (
              <Option key={category._id}>{category.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Brand"
          name="brand"
          rules={[
            { required: true, message: "Please enter the product brand" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Quantity"
          name="quantity"
          rules={[
            { required: true, message: "Please enter the product quantity" },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        {typeof productData !== "undefined" && (
          <Form.Item
            label="Delete Product"
            name="isDeleted"
            valuePropName="checked"
            // style={{width: '220px'}}
          >
            <Switch
              defaultChecked={deleteProduct}
              checked={deleteProduct}
              onChange={handleSwitchChange}
            />
          </Form.Item>
        )}

        <Form.Item label="Images">
          <Upload
            customRequest={customRequest}
            listType="picture-card"
            maxCount={5}
            accept="image/*"
            fileList={productImages}
            onRemove={handleRemove}
          >
            <div>
              <UploadOutlined />
              <div style={{ marginTop: 8 }}>Upload Images</div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Cost Price"
          name="costPrice"
          rules={[
            { required: true, message: "Please enter the product cost price" },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Selling Price"
          name="sellingPrice"
          rules={[
            {
              required: true,
              message: "Please enter the product selling price",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {currentAction}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddProduct;
