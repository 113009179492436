import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import adminDataService from "../../network/api/services/AdminDataServices";
import { Button, Drawer, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CreateCoupons from "./CreateCoupons";

function Coupons() {
  const [getAllCoupons, setgetAllCoupons] = useState([]);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [editCouponData, seteditCouponData] = useState(null);
  const [updateCouponData, setupdateCouponData] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    seteditCouponData(null);
  };

  const handleEdit = (data) => {
    seteditCouponData(data);
    setDrawerVisible(true);
  };

  const handleDelete = async (data) => {
    console.log("Data :", data);
  };

  const columns = [
    {
      name: "Sl. No.",
      cell: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Coupon code",
      selector: "code",
      sortable: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      width: "120px",
    },
    {
      name: "Amount",
      selector: "value",
      sortable: true,
      width: "120px",
    },
    {
      name: "Minimum Cart Value",
      selector: "minimumCartValue",
      sortable: true,
      width: "120px",
    },
    {
      name: "Start Date",
      selector: "startDate",
      sortable: true,
      width: "120px",
    },
    {
      name: "End Date",
      selector: "endDate",
      sortable: true,
      width: "120px",
    },
    {
      name: "Number of Uses",
      selector: "numberOfUses",
      sortable: true,
      width: "120px",
      align: "center",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Popconfirm
            title="Are you sure you want to delete this coupon?"
            onConfirm={() => handleDelete(row)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined />
              Delete
            </Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleEdit(row)}>
            <EditOutlined />
            Edit
          </Button>
        </>
      ),
      sortable: false,
    },
  ];

  const getCoupons = async () => {
    try {
      const response = await adminDataService.getCoupon();
      setgetAllCoupons(response.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    getCoupons();
  }, [updateCouponData]);

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: "16px" }}
        onClick={showDrawer}
      >
        Create Coupon
      </Button>
      <DataTable
        title="Coupon List"
        columns={columns}
        data={getAllCoupons}
        highlightOnHover
        pointerOnHover
      />
      <Drawer
        title="Create Coupon"
        width={"50%"}
        onClose={onClose}
        open={isDrawerVisible}
        placement="right"
      >
        <CreateCoupons
          updateCouponData={updateCouponData}
          setupdateCouponData={setupdateCouponData}
          editCouponData={editCouponData}
          seteditCouponData={seteditCouponData}
          isDrawerVisible={isDrawerVisible}
          setDrawerVisible={setDrawerVisible}
        />
      </Drawer>
    </div>
  );
}

export default Coupons;
