import { useState } from "react";
import { Button, Input, Switch, Form, Upload, message } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dataService from "../../network/api/services/dataService";
// import { callApiWithImage } from '../../formatter/ImageConverter';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { imageUrlToFile } from "../../formatter/ImageConverter";
import ImageDisplay from "../../components/ImageDisplay";
import { toast } from "react-toastify";
import { imageTypeValid } from "../../formatter/ValidImageType";

const AddBanners = ({
  isDrawerVisible,
  setIsDrawerVisible,
  addedNewBanner,
  setaddedNewBanner,
  editedData,
  setFile,
  file,
  setEditedData,
  setbannerAction,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [bannerImage, setbannerImage] = useState("");
  const [bannerId, setbannerId] = useState("");

  // const [file, setFile] = useState(null);
  const [defaultFileList, setdefaultFileList] = useState([
    {
      url: "",
    },
  ]);

  // const defaultFileList = [
  //   {
  //     url: 'https://cdn.pixabay.com/photo/2023/11/24/10/15/cockatoo-8409655_1280.jpg', // URL of your default image
  //   }
  // ];

  const navigate = useNavigate();

  //   const onFinish = (values) => {
  //     setIsSubmitting(true);

  //     setTimeout(() => {
  //       console.log('Submitted values:', values);
  //       message.success('Banner added successfully',title,visible);
  //       setIsSubmitting(false);
  //     //   form.resetFields();
  //     }, 1000);
  //   };

  //   const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  //   };

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  // const customRequest = ({ file, onSuccess, onError }) => {
  //   setTimeout(() => {
  //     onSuccess();
  //     uploadImage(file);
  //   }, 500);
  // };

  const uploadImage = async (file) => {
    console.log(file, "file");
    try {
      if (file.status !== "error") {
        console.log(file, "Image upload");
        const data = await callApiWithImage(file);
        console.log("data:", data);
      } else {
        message.error("Image upload failed");
      }
    } catch (error) {
      console.error("Error during image upload:", error);
      message.error("Image upload failed");
    }
  };

  const callApiWithImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dataService.uploadBannerImage(formData);
      console.log(response, "response ");
      if (response.data) {
        setbannerImage(response.data);
      } else {
        alert("Image upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSwitchChange = (checked) => {
    setVisible(checked);
  };

  const uploadBanner = async () => {
    setIsSubmitting(true);

    console.log(typeof editedData, "Banner added successfully", title, visible);
    const data = {
      photo: bannerImage,
      visible,
      title,
    };
    console.log(data, "data banner", typeof editedData == null);
    if (bannerImage) {
      try {
        const values = await form.validateFields();
        // const { title } = values;
        console.log(values, "values");
        console.log("uploadBanner uploadBanner");

        let response;
        if (!editedData) {
          console.log(typeof editedData == null, "if");
          response = await dataService.addBanner(data);
          console.log(response, "add banner response");
        } else {
          console.log(typeof editedData == null, "else");
          response = await dataService.editBanner(data, bannerId);
          console.log(response, "edit banner response");
        }

        console.log(response, "banner response");
        if (response.success === true) {
          setaddedNewBanner(!addedNewBanner);
          setVisible(false);
          setbannerImage("");
          setTitle("");
          setIsDrawerVisible(false);
          console.log("success banner");
          setEditedData(null);
          setFile(null);
        } else {
          alert("something went wrong");
        }
        console.log(title);
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        // if (error.name === 'ValidationError') {
        //   // Log validation error messages to the console
        //   console.error('Validation Errors:', error.errors);
        // } else {
        //   console.error('Error:', error);
        // }
      }
    } else {
      toast.warning("Please upload image");
    }
    setIsSubmitting(false);
  };

  const handleUpload = (e) => {
    console.log(e.target.files[0], "e.target.files[0]");
    const selectedFile = e.target.files[0];
    const imageType = imageTypeValid(selectedFile.name);
    if (imageType) {
      uploadImage(selectedFile);
      setFile(selectedFile);
    } else {
      toast.error("Invalid file type.");
    }
  };

  const handleDelete = () => {
    setFile(null);
    setbannerImage("");
  };

  const handleToggle = () => {
    setVisible(!visible);
  };
  const convertImage = async () => {
    const imageFile = await imageUrlToFile(editedData.photo);
    console.log(imageFile, "imageFile");
    setFile(imageFile);

    // form.setFieldsValue({
    //   photo:imageFile,

    // });
  };

  useEffect(() => {
    console.log(editedData, "editedData", file);
    if (editedData) {
      console.log(editedData, "useeffect ");
      setdefaultFileList([{ url: editedData.photo }]);
      setTitle(editedData.title);
      setVisible(editedData.visible);
      setbannerId(editedData.orderId);
      setbannerImage(editedData.photo);
      setbannerAction("Update Banner");
      // setFile(editedData.photo);
      convertImage();
      form.setFieldsValue({
        title: editedData.title,
        visible: editedData.visible,
      });
    } else {
      console.log("empty");
      setTitle("");
      setVisible(false);
      form.setFieldsValue({
        title: "",
        visible: false,
      });
    }
  }, [editedData, isDrawerVisible]);

  return (
    <Form
      form={form}
      layout="vertical"
      //   onFinish={onFinish}
      //   onFinishFailed={onFinishFailed}
    >
      {/* <Form.Item
        label="Photo"
        name="photo"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Please upload a Image' }]}
      >
        <Upload
          customRequest={customRequest}
          maxCount={1}
          listType="picture"
          accept="image/*"
      
          defaultFileList={defaultFileList}
        //   onChange={({ file }) => {
        //     if (file.status === 'error') {
        //       message.error('Image upload failed');
        //     }else{
        //       console.log(file,'Image upload') 
        //       uploadImage(file) 
        //     }
        //   }}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item> */}

      {/* <div>
        <label>Select image :</label> 
      {  !file && <Input
          type="file"
          accept="image/*"
          onChange={handleUpload}
        /> }

        {file && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', border: "1px solid grey", justifyContent: "space-between", padding: "5px 8px" }}>
            <img
              src={typeof file == "string" ? file : URL.createObjectURL(file)}
              alt="Preview"
              style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px', border: '1px solid #ccc' }}
            />
            <FaTrash onClick={handleDelete} style={{ cursor: 'pointer', fontSize: "28px" }} />
          </div>
        )}
      </div> */}

      <ImageDisplay
        file={file}
        handleUpload={handleUpload}
        handleDelete={handleDelete}
      />

      <Form.Item
        label="Visible :"
        name="visible"
        valuePropName="checked"
        style={{ width: "50px" }}
      >
        <Switch
          defaultChecked={visible}
          checked={visible}
          onChange={handleSwitchChange}
        />
      </Form.Item>

      {/* <div style={{ width: '50px', display: "flex", marginTop: "5px", alignItems: 'center' }}>
        <label>Visible:  </label>
        <input
          style={{ marginLeft: "5px" }}
          type="checkbox"
          defaultChecked={visible}
          checked={visible}
          onChange={(e) => handleSwitchChange(e.target.checked)}
        />
      </div> */}

      {/* <label>Title :</label> */}

      {/* <input value={title} style={{ marginBottom: "5px", border: "1px solid black" }} onChange={(e) => {
        setTitle(e.target.value)
      }} placeholder="Enter title" /> */}

      <Form.Item
        label="Title :"
        name="title"
        rules={[
          {
            required: true,
            message: "Please enter a title",
          },
          {
            min: 3,
            message: "Title must be at least 3 characters",
          },
        ]}
      >
        <Input
          defaultValue={title}
          onChange={(e) => {
            console.log(e.target.value, "e.target.value");
            setTitle(e.target.value);
          }}
          placeholder="Enter title"
        />
      </Form.Item>

      <Form.Item>
        <Button onClick={uploadBanner} type="primary" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddBanners;
