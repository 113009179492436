import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import dataService from '../../network/api/services/dataService';
import "./ProductInfo.css"

function ProductInfo() {
  const [product, setproduct] = useState([])
  const { id } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const productImages = [
    {
      id: 1,
      name: 'Product 1',
      price: 100,
      images: 'https://img.freepik.com/free-photo/workplace-business-modern-male-accessories-laptop-black-background_155003-3944.jpg?size=626&ext=jpg',
    },
    {
      id: 2,
      name: 'Product 2',
      price: 150,
      images: 'https://img.freepik.com/free-photo/workplace-business-modern-male-accessories-laptop-white_155003-1722.jpg?size=626&ext=jpg',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 75,
      images: 'https://img.freepik.com/premium-psd/bluetooth-earphones-mockup-front-view_1332-25207.jpg?size=626&ext=jpg',
    },
    {
      id: 4,
      name: 'Product 4',
      price: 75,
      images: 'https://img.freepik.com/free-photo/pink-headphones-wireless-digital-device_53876-96804.jpg?size=626&ext=jpg',
    },
  ];

  const handleImageScroll = (direction) => {
    if (direction === 'next') {
      if (currentImageIndex < productImages.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    } else if (direction === 'prev') {
      if (currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    }
  };



  const productInfo = {
    _id: "65326e8f10274942e5e54a65",
    name: "A list of key-value pairs that describe the product's attributes.",
    description: "A list of key-value pairs that describe the product's attributes.",
    price: 11,
    category: "Electronics",
    active: true,
    brand: "Samsung",
    inventoryQuantity: 10,
    images: [
      "[Image URL 1, Image URL 2, ...]"
    ],
    createdBy: "652d153fbc5f7215be0659e2",
    costPrice: 90,
    sellingPrice: 80,
    isApproved: false,
    reviews: [],
    createdAt: "2023-10-20T12:11:59.234Z",
    updatedAt: "2023-10-20T12:11:59.234Z",
  }

  const getProduct = async () => {
    console.log("getProductInfo")
    try {
      const response = await dataService.getProductInfo(id);
      console.log(response, "response products")
      // setproduct([])
      // setTimeout(() => {           
      product.push(response.data)
      // }, 1000);
      setCurrentImageIndex(0)


    }
    catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    setCurrentImageIndex(1)
    if (!product.length > 0) {
      getProduct()

    }
    console.log("product product", product.length)
    setTimeout(() => {
      console.log(product, "product product 2")
    }, 1500);
  }, [product.length])

  return (
    <div className="product-details">
      {
        console.log(product, "product product 222")

      }
      <div className="product-images">
        <div className="image-container">
          <button className="scroll-button prev" onClick={() => handleImageScroll('prev')}></button>
          <img

            src={productImages[currentImageIndex].images}
            alt={`Product Image ${currentImageIndex + 1}`}
          />
          <button className="scroll-button next" onClick={() => handleImageScroll('next')}></button>
        </div>

      </div>
      <div className="product-info">
        <h1>{product[0]?.name}</h1>
        <p className="description">{product[0]?.description}</p>
        <div className="price">
          <span className="original-price">${product[0]?.price}</span>
          <span className="discounted-price">${product[0]?.sellingPrice}</span>
        </div>
        <p className="category">Category: {product[0]?.category}</p>
        <p className="brand">Brand: {product[0]?.brand}</p>
        <p className="stock">In Stock: {product[0]?.inventoryQuantity} units</p>
        <button className="add-to-cart-button">Add to Cart</button>
      </div>
    </div>
  )
}

export default ProductInfo
