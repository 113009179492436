import { base_url } from '../config';
import { FORGOT_PASSWORD, RESET_USER_PASSWORD, RESET_VENDOR_PASSWORD, SIGN_IN, VERIFY_USER_OTP, VERIFY_VENDOR_OTP } from '../endpoint';
import { apiPost } from '../helpers/apiHelpers';

 const login = async (userData) => {
  console.log(userData,"userData from")
  try {
    const response = await apiPost(`${base_url}${SIGN_IN}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

 const forgotPassword = async (userData) => {
  try {
    const response = await apiPost(`${base_url}${FORGOT_PASSWORD}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

const verifyUserOtp = async (userData) => {
  try {
    const response = await apiPost(`${base_url}${VERIFY_USER_OTP}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

const resetUserPassword = async (userData) => {
  try {
    const response = await apiPost(`${base_url}${RESET_USER_PASSWORD}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

const verifyOTP = async (userData) => {
  try {
    const response = await apiPost(`${base_url}${VERIFY_VENDOR_OTP}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

const resetPassword = async (userData) => {
  try {
    const response = await apiPost(`${base_url}${RESET_VENDOR_PASSWORD}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

const authService = {
  login,
 forgotPassword,
 verifyUserOtp,
 resetUserPassword,
 verifyOTP,
 resetPassword
};

export default authService;
