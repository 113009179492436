import React, { useState, useEffect } from "react";
import { Drawer, Button, Space, Popconfirm } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddBanners from "./AddBanners";
import DataTable from "react-data-table-component";
import dataService from "../../network/api/services/dataService";
import "./Banners.css";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
} from "@chakra-ui/react";

function Banners() {
  const [isLoading, setisLoading] = useState(true);
  const [bannerList, setbannerList] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [addedNewBanner, setaddedNewBanner] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [file, setFile] = useState(null);
  const [bannerAction, setbannerAction] = useState("Add Banner");

  const handleAddBannerClick = () => {
    setIsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setEditedData(null);
    setFile(null);
    setTimeout(() => {
      setbannerAction("Add Banner");
    }, 100);
  };

  const columns = [
    {
      name: "Image",
      selector: "photo",
      sortable: true,
      cell: (row) => (
        <img
          src={row.photo}
          alt={row.title}
          style={{ width: "60px", height: "60px" }}
        />
      ),
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => <span className="title-column">{row.title}</span>,
    },
    {
      name: "Visible",
      selector: "visible",
      sortable: true,
      cell: (row) =>
        row.visible ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <Flex direction={{ md: "column", lg: "row" }} gap="10px">
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(row)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="primary" danger>
              <DeleteOutlined />
              Delete
            </Button>
          </Popconfirm>
          <Button size="small" type="primary" onClick={() => handleEdit(row)}>
            <EditOutlined />
            Edit
          </Button>
        </Flex>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditedData(record);
    setIsDrawerVisible(true);
  };

  const handleDelete = async (record) => {
    try {
      setisLoading(false);
      const response = await dataService.deleteBanner(record.orderId);
      if (response.success === true) {
        getBanners();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = bannerList.map((order) => ({
    orderId: order._id,
    photo: order.photo,
    title: order.title,
    visible: order.visible,
  }));

  const getBanners = async () => {
    try {
      setisLoading(false);
      const response = await dataService.getBanners();
      setbannerList(response.data);
      setisLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanners();
  }, [addedNewBanner]);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddBannerClick}
        >
          Add Banners
        </Button>
      </div>

      <Box display={{ base: "none", md: "block" }}>
        <DataTable
          columns={columns}
          data={data}
          progressPending={!isLoading}
          responsive
          progressComponent={<h1>Loading...</h1>}
          customStyles={{
            headCells: { style: { fontSize: "15px" } },
            rows: { style: { padding: "10px", fontSize: "15px" } },
          }}
        />
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        {bannerList.map((banner) => (
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            style={{ marginBottom: "10px" }}
            size={"xs"}
            padding={"10px"}
            key={banner._id}
            alignItems={"center"}
          >
            <Box>
              <Image
                alt={banner.title}
                height={"100px"}
                width={{ base: "200px", sm: "150px" }}
                objectFit={"cover"}
                src={banner.photo}
                marginX={{ base: "auto", sm: "10px" }}
              />
            </Box>
            <CardBody>
              <Heading size="sm">{banner.title}</Heading>
            </CardBody>
            <CardFooter
              display={"flex"}
              alignItems={"center"}
              flexDirection={{ base: "row", sm: "column" }}
              justify={"space-around"}
              gap={"10px"}
            >
              <Button
                type="primary"
                // onClick={() => handleEditCategory(category)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                // onClick={() => handleDeleteCategory(category)}
              >
                Delete
              </Button>
            </CardFooter>{" "}
          </Card>
        ))}
      </Box>

      <Drawer
        title={bannerAction}
        placement="right"
        closable={true}
        onClose={handleCloseDrawer}
        open={isDrawerVisible}
        width={{ xs: 300, sm: 400, md: 500, lg: 600, xl: 700, xxl: 800 }}
      >
        <form>
          <AddBanners
            setbannerAction={setbannerAction}
            isDrawerVisible={isDrawerVisible}
            setFile={setFile}
            file={file}
            editedData={editedData}
            setEditedData={setEditedData}
            setIsDrawerVisible={setIsDrawerVisible}
            addedNewBanner={addedNewBanner}
            setaddedNewBanner={setaddedNewBanner}
          />
        </form>
      </Drawer>
    </div>
  );
}

export default Banners;
