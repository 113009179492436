import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";

const Map = ({ defaultLocation, onLocationChange }) => {
  const initialLocation = { lat: 12.9716, lng: 77.5946 };
  const [location, setLocation] = useState(initialLocation);
  useEffect(() => {
    if (defaultLocation) {
      setLocation({lat : defaultLocation.lat, lng : defaultLocation.lng});
    } else {
      setLocation(initialLocation);
    }
  }, [defaultLocation]);

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const handleMapClick = (e) => {
    const updatedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    onLocationChange(updatedLocation);
    setLocation(updatedLocation);
  };

  const handlePlaceChanged = (place) => {
    if (place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      setLocation({ lat, lng });
      console.log("Selected Location:", { lat, lng });
    }
  };

  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyBNYU4e26ZzfCA2D8AbxHTL7eziHgP_Irs" libraries={["places"]}>
      <GoogleMap mapContainerStyle={mapStyles} center={location} zoom={15} onClick={handleMapClick}>
          <Marker
            // position={location}
            // onDragEnd={(e) => handleMarkerDragEnd(e)}
            position={{ lat: location.latitude, lng: location.longitude }}
          />

          <Autocomplete
            onLoad={(autocomplete) =>
              console.log("Autocomplete loaded:", autocomplete)
            }
            onPlaceChanged={(place) => handlePlaceChanged(place)}
          >
            <input
              type="text"
              placeholder="Search for a place"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                marginTop: `7px`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
                zIndex: 1,
              }}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
