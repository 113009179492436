import { Input } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { FaTrash } from 'react-icons/fa'

function ImageDisplay({file,handleUpload,handleDelete}) {


    useEffect(() => {
    console.log(file,handleUpload,handleDelete,"==>")
    }, [])
  return (
    <div>
            <div>
        <label>Select image :</label> 
      {  !file && <Input
          type="file"
          accept="image/*"
          onChange={handleUpload}
        /> }

        {file && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', border: "1px solid grey", justifyContent: "space-between", padding: "5px 8px" }}>
            <img
            
              src={typeof file == "string" ? file : URL.createObjectURL(file)}
              alt="Preview"
              style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px', border: '1px solid #ccc' }}
            />
            <FaTrash onClick={handleDelete} style={{ cursor: 'pointer', fontSize: "28px" }} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageDisplay
