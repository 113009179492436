import React, { useState, useEffect } from "react";
import { Table, Button, Drawer, Space, Popconfirm } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dataService from "../../../network/api/services/dataService";
import "./Banners.css";
import Addbrand from "../../../pages/Addbrand";
import AddAdminBanners from "./AddAdminBanners ";
function AdminBanners() {
  const [isLoading, setisLoading] = useState(true);
  const [bannerList, setbannerList] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [addedNewBanner, setaddedNewBanner] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [file, setFile] = useState(null);
  const [bannerAction, setbannerAction] = useState("Add Banner");

  const handleAddBannerClick = () => {
    setIsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setEditedData(null);
    setFile(null);
    setTimeout(() => {
      setbannerAction("Add Banner");
    }, 100);
  };

  const columns = [
    {
      title: "Order ID",
      align: "center",
      dataIndex: "orderId",
      className: "order-id-column",
    },
    {
      title: "Image",
      align: "center",
      dataIndex: "photo",
      className: "photo-column",
      render: (text, record) => (
        <img
          src={text}
          alt={record.title}
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Title",
      align: "center",
      dataIndex: "title",
      className: "title-column",
    },
    {
      title: "Visible",
      align: "center",
      dataIndex: "visible",
      className: "visible-column",
      render: (text) =>
        text ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined />
              Delete
            </Button>
          </Popconfirm>
          <Button type="link" onClick={() => handleEdit(record)}>
            <EditOutlined />
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditedData(record);
    setIsDrawerVisible(true);
  };

  const handleDelete = async (record) => {
    console.log("Delete clicked for:", record);
    try {
      setisLoading(false);
      const response = await dataService.deleteBanner(record.orderId);
      console.log(response, "response products");
      if (response.success === true) {
        getBanners();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = bannerList?.map((order, ind) => ({
    key: order?._id,
    orderId: order?._id,
    photo: order?.photo,
    title: order?.title,
    visible: order?.visible,
  }));

  const getBanners = async () => {
    try {
      setisLoading(false);
      
      const response = await dataService.getAdminBanners();
      console.log(response, "response admin banners");
      setbannerList(response.data);
      setisLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanners();
  }, [addedNewBanner]);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddBannerClick}
        >
          Add Banners
        </Button>
      </div>

      <div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
        />
      </div>

      <Drawer
        title={bannerAction}
        placement="right"
        closable={true}
        onClose={handleCloseDrawer}
        open={isDrawerVisible}
        width={400}
      >
        <form>
          <AddAdminBanners
            setbannerAction={setbannerAction}
            isDrawerVisible={isDrawerVisible}
            setFile={setFile}
            file={file}
            editedData={editedData}
            setEditedData={setEditedData}
            setIsDrawerVisible={setIsDrawerVisible}
            addedNewBanner={addedNewBanner}
            setaddedNewBanner={setaddedNewBanner}
          />
        </form>
      </Drawer>
    </div>
  );
}

export default AdminBanners;
