import React, { useEffect } from "react";
import { Table, Button, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import dataService from "../../network/api/services/dataService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductList.css";
import BackDropLoader from "../../components/BackDropLoader";
const Productlist = () => {
  const navigate = useNavigate();
  const [productList, setproductList] = useState([]);
  const [updateLatestProduct, setupdateLatestProduct] = useState(true);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      title: "Sl. No",
      dataIndex: "key",
      align: "center",
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      sorter: (a, b) => a.name.length - b.name.length,
      align: "center",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      align: "center",
    },
    {
      title: "SellingPrice",
      dataIndex: "sellingPrice",
      sorter: (a, b) => a.sellingPrice - b.sellingPrice,
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      align: "center",
    },
    {
      title: "Update",
      dataIndex: "update",
      className: "update-column",
      align: "center",
      render: (text, record) => (
        <Button
          onClick={() => {
            console.log(text, record, "details");
            // navigate('/admin/add-product');
            navigate(`/vendor/add-product/`, {
              state: { productData: text },
            });
          }}
          type="primary"
        >
          Update
        </Button>
      ),
    },
  ];

  const data1 = [];
  for (let i = 0; i < productList.length; i++) {
    data1.push({
      key: i + 1,
      id: productList[i]._id,
      name: productList[i].name,
      brand: productList[i].brand,
      price: productList[i].price,
      category: productList[i].category,
      sellingPrice: productList[i].sellingPrice,
      inventoryQuantity: productList[i].inventoryQuantity,
      quantity: productList[i].quantity,
      description: productList[i].description,
      update: productList[i],

      action: (
        <>
          <Link to="/" className=" fs-3 text-danger">
            <BiEdit />
          </Link>
          <Link className="ms-3 fs-3 text-danger" to="/">
            <AiFillDelete />
          </Link>
        </>
      ),
    });
  }
  console.log(data1);

  const getProductList = async () => {
    try {
      setIsRefreshLoading(true);
      const response = await dataService.getProducts();
      console.log(response, "response products");
      setproductList(response.data);
      setIsRefreshLoading(false);
    } catch (error) {
      console.log(error);
      setIsRefreshLoading(false);
    }
  };

  useEffect(() => {
    getProductList();
  }, [updateLatestProduct]);

  const filteredData = data1.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.brand.toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.price).includes(searchText) ||
      String(item.sellingPrice).includes(searchText)
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <div>
      {isRefreshLoading && <BackDropLoader />}

      <div>
        <Input
          placeholder="Search products"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: "100%", marginBottom: 1 }}
        />
        <div style={{ overflowX: "scroll" }}>
          <Table
            columns={columns}
            style={{ minWidth: "1200px", overflowX: "scroll" }}
            dataSource={filteredData}
            pagination={{ pageSize: 10 }}
           
          />
        </div>
      </div>
    </div>
  );
};

export default Productlist;
