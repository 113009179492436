import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashbaord/Dashboard";
import Login from "./pages/login/Login";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Enquiries from "./pages/Enquiries";
import Bloglist from "./pages/Bloglist";
import Blogcatlist from "./pages/Blogcatlist";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Colorlist from "./pages/Colotlist";
import Categorylist from "./pages/Categorylist";
import Brandlist from "./pages/Brandlist";
import Productlist from "./pages/productList/Productlist";
import Addblog from "./pages/Addblog";
import Addblogcat from "./pages/Addblogcat";
import Addcolor from "./pages/Addcolor";
import Addcat from "./pages/Addcat";
import Addbrand from "./pages/Addbrand";
import Couponlist from "./pages/Couponlist";
import AddCoupon from "./pages/AddCoupon";
import ViewEnq from "./pages/ViewEnq";
import ViewOrder from "./pages/ViewOrder";
import ProductInfo from "./pages/productInfo/ProductInfo";
import AddProduct from "./pages/addProduct/Addproduct";
import ProtectedRoute from "./components/ProtectedRoute";
import BulkProducts from "./pages/addBulkProducts/BulkProducts";
import Account from "./pages/Account";
import SignUp from "./pages/SignUp";
import Delivery from "./pages/Delivery/Delivery";
import Return from "./pages/Return";
//Super Admin Imports

import Layout from "./SuperAdminUI/Components/Layout";
import ApproveRequests from "./SuperAdminUI/Pages/ApproveRequests";
import AdminDashboard from "./SuperAdminUI/Pages/AdminDashboard";
import AllProducts from "./SuperAdminUI/Pages/AllProducts";
import Profile from "./SuperAdminUI/Pages/Profile";
import Banners from "./pages/banners/Banners";
import OrderDetails from "./pages/Order-Details/OrderDetails";
import Categories from "./pages/categories/Categories";
import SubmitOTP from "./pages/SubmitOTP";
import UserSubmitOTP from "./User/UserSubmitOTP";
import UserSetPassword from "./User/UserSetPassword";
import ResetPasswordSuccess from "./User/ResetPasswordSuccess";
import AdminBanners from "./SuperAdminUI/Pages/Banner/AdminBanners";
import BulkProductView from "./pages/addBulkProducts/BulkProductView";
import Coupons from "./SuperAdminUI/Pages/Coupons";
import AdminDelivery from "./SuperAdminUI/Pages/AdminDelivery";
export const version = "1.0.10";
function App() {
  // for Production
  // const name = "admin";

  // for Development
  const name = "";
  return (
    <BrowserRouter basename={`${name}`}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-vendor-password" element={<Resetpassword />} />
        <Route path="/verify-vendor-otp" element={<SubmitOTP />} />
        <Route path="/verify-user-otp" element={<UserSubmitOTP />} />
        <Route path="/reset-user-password" element={<UserSetPassword />} />
        <Route
          path="/reset-password-success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/vendor" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="enquiries/:id" element={<ViewEnq />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />
          <Route path="orders" element={<Orders />} />
          <Route path="return" element={<Return />} />
          <Route path="profile" element={<Account />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="category" element={<Addcat />} />
          <Route path="category/:id" element={<Addcat />} />
          <Route path="list-brand" element={<Brandlist />} />
          <Route path="brand" element={<Addbrand />} />
          <Route path="brand/:id" element={<Addbrand />} />
          {/* <Route path="list-product" element={<Productlist />} /> */}
          {/* <Route path="product/:id" element={<ProductInfo />} /> */}

          {/* <Route path="product" element={<AddProduct />} /> */}
          {/* <Route
           path="/admin"
            element={<ProtectedRoute ><Dashboard /></ProtectedRoute>}
          /> */}
          <Route
            path="add-bulk-product"
            element={
              <ProtectedRoute>
                <BulkProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="add-product"
            element={
              <ProtectedRoute>
                <AddProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="product/:id"
            element={
              <ProtectedRoute>
                <ProductInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="list-product"
            element={
              <ProtectedRoute>
                <Productlist />
              </ProtectedRoute>
            }
          />
          <Route
            path="banners"
            element={
              <ProtectedRoute>
                <Banners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vendor/order-details/:id"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="categories"
            element={
              <ProtectedRoute>
                <Categories />
              </ProtectedRoute>
            }
          />
          <Route
            path="bulk-product-view"
            element={
              <ProtectedRoute>
                <BulkProductView />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/admin" element={<Layout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="AllProducts" element={<AllProducts />} />
          <Route path="approve-request" element={<ApproveRequests />} />
          <Route path="delivery" element={<AdminDelivery />} />
          <Route path="profile" element={<Profile />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="banners" element={<AdminBanners />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
