import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  Spinner,
  Alert,
  Grid,
  GridItem,
  Badge,
  SimpleGrid,
  Divider,
  VStack,
  Select,
  Button,
  Flex,
  Container,
} from "@chakra-ui/react";
import dataService from "../../network/api/services/dataService";
import { message } from "antd";

const OrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryType, setDeliveryType] = useState("");
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await dataService.getOrderDetails(id);
        setOrderDetails(response.data);
        setDeliveryType(response.data.deliveryType);
        setSelectedDeliveryBoy(response.data.deliveryBoy || null);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Error fetching order details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDeliveryBoys = async () => {
      try {
        const response = await dataService.getAllDeliveryBoys();
        setDeliveryBoys(response.data);
      } catch (error) {
        console.error("Error fetching delivery boys:", error);
      }
    };

    fetchOrderDetails();
    fetchDeliveryBoys();
  }, []);

  const handleUpdateOrder = async () => {
    let payload;

    if (deliveryType === "homeDelivery") {
      payload = {
        deliveryBoy: selectedDeliveryBoy || null,
        status: orderDetails.status,
      };
    } else {
      payload = {
        status: orderDetails.status,
      };
    }

    console.log(payload);

    try {
      const response = await dataService.updateOrder(id, payload);
      console.log(response.data);
      message.success("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
      message.error(error.response?.data?.error || "An error occurred");
    }
  };

  const statusOptions =
    deliveryType === "homeDelivery"
      ? [
          { label: "Placed", value: "Placed" },
          { label: "Processing", value: "Processing" },
          { label: "Packed", value: "Packed" },
          { label: "Shipped", value: "Shipped" },
          { label: "Out For Delivery", value: "Out For Delivery" },
          { label: "Delivered", value: "Delivered" },
          { label: "Cancelled", value: "Cancelled" },
        ]
      : [
          { label: "Placed", value: "Placed" },
          { label: "Ready For Pickup", value: "Packed" }, 
          { label: "Delivered", value: "Delivered" },
          { label: "Cancelled", value: "Cancelled" },
        ];

  return (
    <Box background={"white"} p="5" borderRadius={"10px"}>
      <Text fontSize="3xl" fontWeight="bold" mb="4">
        Order Details
      </Text>

      <VStack align="start" spacing="4">
        <Text fontSize="lg">
          <strong>Order ID:</strong> &nbsp; {orderDetails._id}
        </Text>

        <Text fontSize="lg">
          <strong>Delivery Type:</strong> &nbsp; {deliveryType}
        </Text>

        <Flex align="start" gap="4">
          <Text fontSize="lg">
            <strong>Status:</strong>
          </Text>
          <Select
            size={"sm"}
            value={orderDetails.status}
            onChange={(e) =>
              setOrderDetails({ ...orderDetails, status: e.target.value })
            }
          >
            {statusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Flex>

        <Divider />

        <Text fontWeight="bold" fontSize="xl">
          Products
        </Text>
        <SimpleGrid columns={[1, 2]} spacing="4" width="100%">
          {orderDetails.products?.map((product) => (
            <Box key={product._id} borderWidth="1px" borderRadius="lg" p="4">
              <Text fontSize="xl" fontWeight="bold">
                {product.name}
              </Text>
              <Text fontSize="lg">Quantity: {product.quantity}</Text>
              <Text fontSize="lg">MRP: ₹{product.price}</Text>
            </Box>
          ))}
        </SimpleGrid>

        <Divider />

        <Text fontWeight="bold" fontSize="xl">
          Order Summary
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          <GridItem>
            <Text fontSize="lg">
              <strong>Subtotal:</strong> ₹{orderDetails.subTotal}
            </Text>
            <Text fontSize="lg">
              <strong>Delivery Charge:</strong> ₹{orderDetails.deliveryCharge}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="lg">
              <strong>Total Discount:</strong> ₹{orderDetails.totalDiscount}
            </Text>
          </GridItem>
        </Grid>

        <Divider />

        <Text fontWeight="bold" fontSize="xl">
          Payment Details
        </Text>
        <VStack align="start" spacing="2">
          <Text fontSize="lg">
            <strong>Payment Type:</strong> {orderDetails.paymentType}
          </Text>
          <Text fontSize="lg">
            <strong>Payment Status:</strong> {orderDetails.paymentStatus}
          </Text>
          <Text fontSize="lg">
            <strong>Order Code:</strong> {orderDetails.code}
          </Text>
        </VStack>

        <Divider />

        <Text fontWeight="bold" fontSize="xl">
          Order Dates
        </Text>
        <VStack align="start" spacing="2">
          <Text fontSize="lg">
            <strong>Created At:</strong>{" "}
            {new Date(orderDetails.createdAt).toLocaleString()}
          </Text>
          <Text fontSize="lg">
            <strong>Updated At:</strong>{" "}
            {new Date(orderDetails.updatedAt).toLocaleString()}
          </Text>
        </VStack>

        {orderDetails.deliveryType === "homeDelivery" && (
          <Box>
            <Divider />
            <Text fontWeight="bold" fontSize="xl">
              Assign Delivery Boy
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap="4" alignItems="center">
              <GridItem>
                <Select
                  placeholder="Select Delivery Boy"
                  value={selectedDeliveryBoy}
                  onChange={(e) => setSelectedDeliveryBoy(e.target.value)}
                >
                  {deliveryBoys?.map((boy) => (
                    <option key={boy._id} value={boy._id}>
                      {boy.profile.firstName} {boy.profile.lastName}
                    </option>
                  ))}
                </Select>
              </GridItem>
            </Grid>

            {orderDetails.assignedDeliveryBoy && (
              <Text mt="4">
                <strong>Assigned Delivery Boy:</strong>{" "}
                {orderDetails.assignedDeliveryBoy}
              </Text>
            )}
          </Box>
        )}

        <Divider />

        <GridItem>
          <Button colorScheme="blue" size={"lg"} onClick={handleUpdateOrder}>
            Update Order
          </Button>
        </GridItem>
      </VStack>
    </Box>
  );
};

export default OrderDetails;
