import axios from 'axios';

export const apiGet = async (url, params = {}) => {
  try {
    const token = getTokenFromLocalStorage(); 
    const response = await axios.get(url, {
      params,
      headers: {
        Authorization: token, 
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  };
};

export const apiPost = async (url, data = {}) => {
  try {
    const token = getTokenFromLocalStorage(); 
    const response = await axios.post(url, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiPut = async (url, data = {}) => {
  const token = getTokenFromLocalStorage(); 
  try {
    const response = await axios.put(url, data,
      {
        headers: {
          Authorization: token,
        },
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const apiDelete = async (url) => {
  const token = getTokenFromLocalStorage(); 

  try {
    const response = await axios.delete(url,{
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};



const getTokenFromLocalStorage = () => {
    return localStorage.getItem('jwtToken'); 
  };
  