import React, { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import authService from "../../network/api/services/authService";
import { toast } from "react-toastify";
import { Button, Form, Input } from "antd";
import { FormControl } from "@chakra-ui/react";
import { message, notification } from 'antd';

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});
const Login = () => {
  // const loginData = useSelector((state) => state.auth);

  const [userData, setuserData] = useState({
    email: "",
    password: "",
  });
  const [isEmailValid, setisEmailValid] = useState(false);
  const [emailError, setemailError] = useState("");
  let isValid;
  let isValidPass;
  const [isPasswordValid, setisPasswordValid] = useState(false);
  const [passwordError, setpasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //   },
  //   validationSchema: schema,
  //   onSubmit: (values) => {
  //     dispatch(login(values));
  //   },
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();
    isValid = validateEmail(userData.email);
    isValidPass = validatePassword(userData.password);

    if (isValid && isValidPass) {
      loginPost();
    }
  };

  const loginPost = async () => {
    try {
      setLoading(true);
      const response = await authService.login(userData);
      console.log(response, "response");

      if (response.success === true) {
        localStorage.setItem("jwtToken", response.data.token);

        if (response.data.role === "admin") {
          localStorage.setItem("emailVendor", response.data.admin.email);
          localStorage.setItem("id", response.data.admin._id);
          localStorage.setItem(
            "name",
            `${response.data.admin.firstName} ${response.data.admin.lastName}`
          );
          navigate("/admin");
        } else if (response.data.role === "vendor") {
          localStorage.setItem("emailVendor", response.data.vendor.email);
          localStorage.setItem("id", response.data.vendor._id);
          localStorage.setItem("name", response.data.vendor.firstName);
          navigate("/vendor");
        } else {
          console.error("Unexpected user role:", response.data.role);
          notification.error({
            message: "Login Error",
            description: "An unexpected user role was encountered during login.",
          });
        }
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error during login:", error);

      if (error.response) {
        notification.error({
          message: error.response.data.message,
        });
      } else if (error.request) {
        // The request was made but no response was received
        notification.error({
          message: "Network Error",
          description: "No response received from the server during login.",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        notification.error({
          message: "Unknown Error",
          description: "An unknown error occurred during login.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const authState = useSelector((state) => state);

  const { user, isError, isSuccess, isLoading, message } = authState.auth;

  const handleChange = (event) => {
    const userCopy = { ...userData };
    userCopy[event.target.name] = event.target.value;
    setuserData(userCopy);
  };

  const validateEmail = (email) => {
    const mailexp =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,12})$/;
    if (mailexp.test(email)) {
      setisEmailValid(true);
      setemailError("");
      return true;
    } else {
      setisEmailValid(false);
      setemailError("enter valid email");
      return false;
    }
  };

  const validatePassword = (password) => {
    const passwordExp = /(?=.\d)(?=.[a-z])(?=.*[A-Z]).{6,}/;
    if (password.length > 3) {
      setisPasswordValid(true);
      setpasswordError("");
      return true;
    } else {
      setisPasswordValid(false);
      setpasswordError(" enter valid password");
      return false;
    }
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     navigate("admin");
  //   } else {
  //     navigate("");
  //   }
  // }, [user, isError, isSuccess, isLoading]);

  //   useEffect(() => {
  // console.log(loginData,"loginData useeffect")
  //   }, []);

  return (
    <>
      <div
        className="py-5"
        style={{
          background: "#f5f5f5",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="bg-white rounded-3 p-4"
          style={{
            border: "1px solid black",
            maxWidth: "400px",
            width: "90%",
            textAlign: "center",
            boxShadow: "rgba(0, 0, 0, 0.55) 0px 5px 15px",
          }}
        >
          <h2 className="title" style={{ fontFamily: "-moz-initial" }}>Login</h2>
          <p>Login to your account to continue.</p>
          <div className="error">
            {message.message === "Rejected" && "You are not an Admin"}
          </div>
          <form action="" onSubmit={handleSubmit}>
            <FormControl>
              <label>
                Email Address
              </label>
            <Input
              className="form-control inputData"
              type="text"
              label="Email Address"
              id="email"
              name="email"
              onChange={handleChange}
              val={userData.email}
            />
            </FormControl>

            <div className="error">
              {!isEmailValid ? (
                <span
                  style={{
                    color: "red",
                    display: "block",
                    fontSize: "15px",
                  }}
                >
                  {emailError}
                </span>
              ) : null}
            </div>
            <FormControl>
              <label>
                Password
              </label>
            <Input
              className="form-control inputData"
              type="password"
              label="Password"
              id="pass"
              name="password"
              // onChng={formik.handleChange("password")}
              // onBlr={formik.handleBlur("password")}
              onChange={handleChange}
              val={userData.password}
            />
            </FormControl>
                  
            <div className="error">
              {/* <label>password invalid </label> */}

              {!isPasswordValid ? (
                <span
                  style={{
                    color: "red",
                    display: "block",
                    fontSize: "15px",
                  }}
                >
                  {passwordError}
                </span>
              ) : null}
            </div>

            <button className="btn btn-primary btn-lg w-100" type="submit" style={{fontFamily: "Poppins"}}>
              Login
            </button>
          </form>
          <div className="text-start mt-3">
            <Link to="forgot-password" className="text-decoration-none">
              Forgot Password?
            </Link>
          </div>
          <div className="text-start mt-3">
            
            <Button
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                height: "40px",
                fontFamily: "Poppins",
              }}
              type="primary"
              onClick={() => navigate("/sign-up")}
            >
              SIGN UP
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
