import { Table, Space, Tag } from 'antd';
import React from 'react'
import { useLocation } from "react-router-dom";


function BulkProductView() {
  const location = useLocation();
  const { productDetails } = location?.state || {};

  const responseData = [
    {
      "_id": "65795bfaafc53cb5efd080df",
      "name": "Product-333",
      "description": "First Product-222",
      "price": 100009832479,
      "category": ["655b424d7a54e94ff832867b"],
      "active": true,
      "isDeleted": false,
      "brand": "brand-1",
      "images": [
        "https://ecommercespace.blr1.digitaloceanspaces.com/Product/barr%401702445491552.png",
        "https://ecommercespace.blr1.digitaloceanspaces.com/Category/download%20%281%29%401702375575273.jpg",
        
      ],
      "popular": false,
      "quantity": 50123123122,
      "visible": true,
      "additionalDetails": [],
      "createdBy": "652d153fbc5f7215be0659e2",
      "reviews": 123,
      "ratings": 5,
      "costPrice": 4089,
      "sellingPrice": 50,
      "isApproved": false,
      "createdAt": "2023-12-13T07:23:38.048Z",
      "updatedAt": "2023-12-13T07:23:38.048Z",
      "__v": 0,
      "isUpdated": true
    },
    {
      "_id": "65796504d046f39517ce1fe2",
      "name": "1234567",
      "description": "First Product-222",
      "price": 10000,
      "category": ["655b424d7a54e94ff832867b"],
      "active": true,
      "isDeleted": false,
      "brand": "brand-1",
      "images": [
        "https://ecommercespace.blr1.digitaloceanspaces.com/Product/barr%401702445491552.png",
        "https://ecommercespace.blr1.digitaloceanspaces.com/Product/barr%401702445491552.png",
        
      ],
      "popular": false,
      "quantity": 50123123122,
      "visible": true,
      "additionalDetails": [],
      "createdBy": "652d153fbc5f7215be0659e2",
      "reviews": 123,
      "ratings": 5,
      "isUpdated": false,
      "costPrice": 40,
      "sellingPrice": 50,
      "isApproved": false,
      "createdAt": "2023-12-13T08:02:12.533Z",
      "updatedAt": "2023-12-13T08:02:12.533Z",
      "__v": 0
    }
  ];



      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          align: 'center',

        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          render: (price) => <span>${price}</span>,
        },
        {
          title: 'Brand',
          dataIndex: 'brand',
          key: 'brand',
          align: 'center',

        },
        {
            title: 'Image',
            dataIndex: 'images',
            key: 'images',
          align: 'center',
            render: (images) => (
              <div style={{ display: 'flex', width: `${images.length * 45}px` }}>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Product ${index + 1}`}
                    style={{ width: '40px', height: '40px', objectFit: 'cover', marginRight: '8px' }}
                  />
                ))}
              </div>
            ),
          },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center',

        },
        {
          title: 'Cost Price',
          dataIndex: 'costPrice',
          key: 'costPrice',
          align: 'center',

          render: (costPrice) => <span>${costPrice}</span>,
        },
        {
          title: 'Selling Price',
          dataIndex: 'sellingPrice',
          key: 'sellingPrice',
          align: 'center',

          render: (sellingPrice) => <span>${sellingPrice}</span>,
        },
        {
          title: 'Status',
          dataIndex: 'isUpdated',
          key: 'isUpdated',
          align: 'center',

          render: (active) => (active ? <Tag color="green">New</Tag> : <Tag color="red">Exist</Tag>),
        },
      ];


  return (
    <div>
    {/* <h1>Bulk Product View</h1> */}
    {console.log(productDetails,"productDetails")}
    <Table dataSource={productDetails} columns={columns} rowKey="_id" />
  </div>
  )
}

export default BulkProductView
