import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../network/api/services/authService";
const SubmitOTP = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");

    if (emailParam) {
      setEmail(emailParam);
    } else {
      console.error("Email not found in URL");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);

     const payload = {
        otp: values.otp,
        email: email,
      };

      const response = await authService.verifyOTP(payload);

      localStorage.setItem("jwtToken", response.data);

      setTimeout(() => {
        setLoading(false);
        message.success("OTP verified successfully!");
        form.resetFields();
        navigate(`/reset-vendor-password?email=${email}`);
      }, 1000);




    } catch (error) {
      console.error("Error submitting OTP:", error);

      toast.error("Failed to submit OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}
    >
      <div
        className="bg-white rounded p-5"
        style={{
          border: "1px solid #d9d9d9",
          maxWidth: "500px",
          width: "95%",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          className="title"
          style={{ fontFamily: "sans-serif", marginBottom: "20px" }}
        >
          Submit OTP
        </h2>
        <p style={{ fontSize: "14px", color: "#888", marginBottom: "20px" }}>
          Enter the OTP sent to your email.
        </p>

        <Form
          form={form}
          name="submit_otp"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: "Please enter the OTP!" },
              { len: 5, message: "OTP must be 5 characters long" },
            ]}
          >
            <Input placeholder="OTP" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Submit OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SubmitOTP;
