import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import BackDropLoader from "../../components/BackDropLoader";
import { base_url } from "../../network/api/config";
import adminDataService from "../../network/api/services/AdminDataServices";



function AdminDashboard() {

  const VendorTable = ({ vendors, onUpdateStatus }) => {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Sl. No.</Th>
            <Th >Name</Th>
            <Th>Email</Th>
            <Th>Status</Th>
            <Th>Registered Products</Th>
            <Th>Sales (INR)</Th>
            <Th>Rating</Th>
            <Th>Registration Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vendors.map((vendor, index) => (
            <Tr key={vendor._id}>
              <Td>{index + 1}</Td>
              <Td>{vendor.firstName}  {vendor.lastName}</Td>
              <Td>{vendor.email}</Td>
              <Td>
                {vendor.isApproved ? (
                  <FaCheckCircle color="green" />
                ) : (
                  <FaTimesCircle color="red" />
                )}
              </Td>
              <Td>{vendor.registeredProduct}</Td>
              <Td>{vendor.sales}</Td>
              <Td>{vendor.rating}</Td>
              <Td>{new Date(vendor.registrationDate).toLocaleDateString()}</Td>
              <Td>
                <Button
                  colorScheme={vendor.isApproved ? "red" : "green"}
                  onClick={() => onUpdateStatus(vendor._id, !vendor.isApproved)}
                >
                  {vendor.isApproved ? "Deactivate" : "Activate"}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  };

  const [vendors, setVendors] = useState([]);
  const [error, setError] = useState(null);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const jwtToken = localStorage.getItem("jwtToken");

    const fetchVendors = async () => {
      setIsRefreshLoading(true);
      try {
        const response = await adminDataService.getVendors();
        setVendors(response.data);
        setIsRefreshLoading(false);
      } catch (error) {
        console.error("Error fetching vendors:", error);
        setError("Error fetching vendors. Please try again.");
      } finally {
        setIsRefreshLoading(false);
      }
    };
   useEffect(() => {
    fetchVendors();
  }, []);

  const updateVendorStatus = async (vendorId, newStatus) => {
    setIsRefreshLoading(true);
  
    try {
      const payload = {
        vendorId: vendorId,
        isApproved: newStatus,
      };
  
      const response = await adminDataService.approveVendor(payload);
  
      const updatedVendor = response.data;
  
      setVendors((prevVendors) => {
        return prevVendors.map((vendor) =>
          vendor._id === updatedVendor._id ? updatedVendor : vendor
        );
      });
      fetchVendors();
      setIsRefreshLoading(false);

    } catch (error) {
      console.error("Error updating vendor status:", error);
      setError("Error updating vendor status. Please try again.");
      setIsRefreshLoading(false);
    }
  };
  
  


  return (
    <div>
     {isRefreshLoading && <BackDropLoader />}

      <div>
        <h2>Vendor Management</h2>
        <VendorTable vendors={vendors} onUpdateStatus={updateVendorStatus} />
      </div>
    </div>
  );
}

export default AdminDashboard;
