import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, message } from "antd";
import axios from "axios";
import { base_url } from "../network/api/config";
import dataService from "../network/api/services/dataService";
import { Container, Flex } from "@chakra-ui/react";
import Map from "../components/Map";

const Account = () => {
  const [form] = Form.useForm();
  const jwtToken = localStorage.getItem("jwtToken");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordForm] = Form.useForm();
  const [locationDetails, setLocationDetails] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await dataService.getVendorProfile();
        form.setFieldsValue(response.data);
        const latitude = response.data.location.coordinates[1];
        const longitude = response.data.location.coordinates[0];
  
        setLocationDetails({
          ...locationDetails,
          lat: latitude,
          lng: longitude,
        });
  
        console.log("User profile data AND Location:", {
          locationDetails
        });
      } catch (error) {
        console.error("Error fetching user profile data:", error);
      }
    };
  
    fetchUserProfile();
  }, []); // Empty dependency array means this effect runs once when the component mounts
  

  const onFinish = async (values) => {
    const payload = {
      ...values,
      latitude: locationDetails.lat,
      longitude: locationDetails.lng,

    };
    console.log("Form values:", payload);
  
    try {
      const response = await axios.post(
        `${base_url}/vendors/update-profile`,
        payload,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      console.log("Profile updated successfully:", response);
      message.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Error updating profile");
    }
  };
  
  const handlePasswordUpdate = async (values) => {
    console.log("Password update values:", values);
    setIsModalVisible(false);
  };

  const showPasswordModal = () => {
    setIsModalVisible(true);
  };

  const handleLocationChange = (newLocation) => {
    setLocationDetails((prevLocationDetails) => ({
      ...prevLocationDetails,
      lat: newLocation.lat,
      lng: newLocation.lng,
    }), () => {
      console.log("Updated Location Details:", locationDetails);
    });
  };
  
  

  return (
    <div>
      <h2 className="mb-5 text-center">Vendor Profile</h2>
      <Container maxW="container.md">
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Please enter your last name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Invalid email address" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mobileNumber"
            label="Mobile"
            rules={[
              { required: true, message: "Please enter your mobile number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password />
          </Form.Item>

          <h2 className="mb-5 text-center">Business Profile</h2>

          <Form.Item
            name="bussinessName"
            label="Business Name"
            rules={[{ required: true, message: "Please enter your shop name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="bussinessAddress"
            label="Business Address"
            rules={[
              { required: true, message: "Please enter your Business address" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="bussinessDescription"
            label="Business Description"
            rules={[
              {
                required: true,
                message: "Please enter your Business description",
              },
            ]}
          >
            <textarea />
          </Form.Item>

          {/* <Form.Item
            name="bussinessMail"
            label="Business Email"
            rules={[
              { required: true, message: "Please enter your Business email" },
            ]}
          >
            <Input />
          </Form.Item> */}

          {/* <Form.Item
            name=""
            label="Business Mobile"
            rules={[
              { required: true, message: "Please enter your Business mobile" },
            ]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            name="bussinessWebsite"
            label="Business Website"
            rules={[{ type: "url", message: "Invalid website URL" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="gstNumber"
            label="GST ID No."
            rules={[{ required: true, message: "Please enter your GST ID" }]}
          >
            <Input />
          </Form.Item>

          <div style={{ width: "100%", padding: "10px" }}>
            <Map defaultLocation={locationDetails} onLocationChange={handleLocationChange} />
          </div>

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-around"
          >
            <br />
            {/* <Button size="large" onClick={showPasswordModal}>
              Update Password
            </Button> */}

            <br />

            <Button size="large" type="primary" htmlType="submit">
              Save Profile
            </Button>
          </Flex>
        </Form>
      </Container>

      <Modal
        title="Update Password"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => passwordForm.submit()}
      >
        <Form
          form={passwordForm}
          onFinish={handlePasswordUpdate}
          layout="vertical"
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              { required: true, message: "Please enter your old password" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please enter your new password" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Account;
