import React, { useState, useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import DataTable from "react-data-table-component";
import adminDataService from "../../network/api/services/AdminDataServices";

const dividerStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#1733E9",
  height: "2px",
  width: "100%",
};

const sectionStyle = {
  marginTop: "20px",
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "10px",
  maxHeight: "550px",
  overflowY: "auto",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
};

const AdminDelivery = () => {
  const [loading, setLoading] = useState(false);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [allDeliveryBoys, setAllDeliveryBoys] = useState([]);
  const [form] = Form.useForm();

  const columns = [
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },

    {
      name: "Approval Status",
      selector: "isApproved",
      cell: (row) =>
        row.isApproved ? (
          <span style={{ color: "green" }}>&#10004;</span> // Tick icon
        ) : (
          <span style={{ color: "red" }}>&#10008;</span> // Cross icon
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button type="primary" onClick={() => handleApprove(row)}>
          Approve
        </Button>
      ),
    },
  ];

  const columnsAll = [
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Status",
      selector: "isApproved",
      cell: (row) =>
        row.isApproved ? (
          <span style={{ color: "green" }}>&#10004;</span>
        ) : (
          <span style={{ color: "red" }}>&#10008;</span>
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button type="primary" danger onClick={() => handleUnapprove(row)}>
         Unapprove
        </Button>
      ),
    },
  ];

  const fetchUnapprovedDeliveryBoys = async () => {
    try {
      const response = await adminDataService.getUnapprovedDeliveryBoys();
      setDeliveryBoys(response.data);
    } catch (error) {
      console.error("Error fetching unapproved delivery boys:", error);
    }
  };

  const fetchAllDeliveryBoys = async () => {
    try {
      const response = await adminDataService.allDeliveryBoys();
      setAllDeliveryBoys(response.data);
      console.log("All Delivery Boys:", allDeliveryBoys);
    } catch (error) {
      console.error("Error fetching all delivery boys:", error);
    }   
  }

  useEffect(() => {
    fetchUnapprovedDeliveryBoys();
    fetchAllDeliveryBoys();
  }, []);

  const handleApprove = async (row) => {
    const payload = {
      deliveryBoyId: [row.key],
      isApproved: true,
    };

    try {
      const response = await adminDataService.approveDeliveryBoy(payload);
      console.log("API Response:", response.data);
      fetchUnapprovedDeliveryBoys();
      fetchAllDeliveryBoys();
    } catch (error) {
      console.error("Error approving delivery boy:", error);
    }
  };

  const handleUnapprove = async (row) => {
    const payload = {
      deliveryBoyId: [row.key],
      isApproved: false,
    };

    try {
      const response = await adminDataService.approveDeliveryBoy(payload);
      console.log("API Response:", response.data);
      fetchUnapprovedDeliveryBoys();
      fetchAllDeliveryBoys();
    } catch (error) {
      console.error("Error unapproving delivery boy:", error);
    }
  };

  const onFinish = async (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      password: values.password,
    };

    try {
      const response = await adminDataService.createDeliveryBoy(payload);
      console.log("API Response:", response.data);
      message.success("Delivery boy created successfully");
      form.resetFields();
      fetchUnapprovedDeliveryBoys();
      fetchAllDeliveryBoys();
    } catch (error) {
      console.error("Error creating delivery boy:", error);
      message.error(error.response?.data?.error || "An error occurred");
    }
  }

  return (
    <div>
      <h3>Manage Delivery Boys (Admin)</h3>
      <div style={dividerStyle}></div>

      <div style={sectionStyle}>
       <h4>Create Delivery Boy</h4>
             <Form 
          form={form}
          name="basic"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          {/* First Name */}
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input />
          </Form.Item>

          {/* Phone */}
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Email */}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Password */}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Delivery Boy"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <br />

      <div style={sectionStyle}>
        <h4>Unapproved Delivery Boys</h4>
        <DataTable columns={columns} 
        
        data={deliveryBoys.map((deliveryBoy) => ({
            firstName: deliveryBoy.profile.firstName,
            lastName: deliveryBoy.profile.lastName,
            email: deliveryBoy.email,
            isApproved: deliveryBoy.isApproved,
            key: deliveryBoy._id,
        }))}
        
        pagination />
      </div>

      <br />

      <div style={sectionStyle}>
        <h4>All Delivery Boys</h4>
        <DataTable columns={columnsAll} data={
          allDeliveryBoys.map((deliveryBoy) => ({
            firstName: deliveryBoy.profile.firstName,
            lastName: deliveryBoy.profile.lastName,
            email: deliveryBoy.email,
            isApproved: deliveryBoy.isApproved,
            key: deliveryBoy._id,
          }))
        
        }  />
      </div>
    </div>
  );
};

export default AdminDelivery;
