import React, { useState } from "react";
import { Input, DatePicker, Button, Select, Form, Row, Col } from "antd";
import adminDataService from "../../network/api/services/AdminDataServices";
import "./CreateCoupons.css";
import { useEffect } from "react";
import moment from "moment";

const { Option } = Select;

const CreateCoupons = ({
  editCouponData,
  setDrawerVisible,
  isDrawerVisible,
  setupdateCouponData,
  updateCouponData,
}) => {
  const [couponData, setCouponData] = useState({
    code: "",
    name: "",
    description: "",
    type: "%",
    value: 0,
    minimumCartValue: 0,
    startDate: null,
    endDate: null,
    numberOfUses: 0,
  });
  const [actionbutton, setactionbutton] = useState("Create Coupon");
  const initialValues = {
    code: "",
    name: "",
    description: "",
    type: "%",
    value: 0,
    minimumCartValue: 0,
    startDate: null,
    endDate: null,
    numberOfUses: 0,
  };
  const [form] = Form.useForm();

  const handleChange = (field, value) => {
    setCouponData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDateChange = (field, date) => {
    handleChange(field, date);
  };

  const handleSubmit = async (values) => {
    let response;
    if (editCouponData) {
      console.log("for update", { ...values, id: editCouponData._id });
      const data = { ...values, id: editCouponData._id };
      response = await adminDataService.editCoupon(data);
      console.log(response, "update coupon response");
    } else {
      console.log("for create");
      response = await adminDataService.createCoupon(values);
      console.log(response, "create coupon response");
    }
    if (response) {
      setDrawerVisible(false);
      setupdateCouponData(!updateCouponData);
    }
  };

  useEffect(() => {
    console.log(editCouponData, "editCouponData");
    if (editCouponData) {
      console.log(editCouponData, "if editCouponData");
      form.setFieldsValue({
        // name: editCouponData?.code,
        code: editCouponData?.code,
        description: editCouponData?.description,
        type: editCouponData?.type,
        value: editCouponData?.value,
        minimumCartValue: editCouponData?.minimumCartValue,
        // startDate:editCouponData?.startDate,
        // endDate:editCouponData?.endDate,
        numberOfUses: editCouponData?.numberOfUses,
      });
      setactionbutton("Update Coupon");
    } else {
      console.log(editCouponData, "else editCouponData");
      form.setFieldsValue({
        // name: editCouponData?.code,
        code: "",
        description: "",
        type: "%",
        value: 0,
        minimumCartValue: 0,
        startDate: null,
        endDate: null,
        numberOfUses: 0,
      });
      setactionbutton("Create Cpupon");
    }
  }, [isDrawerVisible, editCouponData]);

  return (
    <div className="coupon-form-container">
      <h2></h2>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Coupon Code"
          name="code"
          rules={[{ required: true, message: "Please enter a coupon code" }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item label="Coupon Name" name="name" rules={[{ required: true, message: 'Please enter a coupon name' }]}>
          <Input />
        </Form.Item> */}
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Discount Type"
          name="type"
          rules={[{ required: true, message: "Please select a discount type" }]}
        >
          <Select>
            <Option value="%">Percentage </Option>
            <Option value="-">Amount</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Discount Value"
          name="value"
          rules={[{ required: true, message: "Please enter a discount value" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Minimum Cart Value" name="minimumCartValue">
          <Input type="number" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Date" name="endDate">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Number of Uses" name="numberOfUses">
          <Input type="number" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {actionbutton}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCoupons;
