  import dataService from '../network/api/services/dataService';

export const callApiWithImage = async (file) => {
    try {

      // Create FormData and append the file to it
      const formData = new FormData();
      formData.append('file', file);

      // Include other payload properties as needed
    //   formData.append('visible', form.getFieldValue('visible'));
    //   formData.append('title', form.getFieldValue('title'));

         const response =  await dataService.uploadBannerImage(formData) 
      console.log(response,"response ")
      return response

    } catch (error) {
      console.error('Error:', error);
      return error
    } 
  };


  export const uploadCategoryImage = async (file) => {
    try {

      const formData = new FormData();
      formData.append('file', file);

         const response =  await dataService.uploadCategoryImage(formData)

      console.log(response,"response ")
      return response

    } catch (error) {
      console.error('Error:', error);
      return error
    }
  };

  export const uploadProductImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response =  await dataService.uploadProductImage(formData)
      console.log(response,"response ")
      return response

    } catch (error) {
      console.error('Error:', error);
      return error
    }
  };




  


export const imageUrlToFile = async (imageUrl) => {
  console.log(imageUrl, "imageUrl")
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object
  const fileName = imageUrl.split('/').pop(); // Extract file name from URL
  const file = new File([blob], fileName, { type: blob.type, lastModified: new Date().getTime() });
  console.log(file, "imageFile file")
  return file;
}