import { base_url } from "../config";
import { apiDelete, apiGet, apiPost, apiPut } from "../helpers/apiHelpers";
import {
    GET_VENDORS,
    APPROVE_VENDOR,
    GET_ALL_PRODUCTS,
    APPROVE_PRODUCTS,
    GET_UNAPPROVED_PRODUCTS,
    GET_VENDOR_PRODUCTS,
    GET_ACCOUNT_DETAILS,
    CREATE_COUPON,
    GET_COUPON,
    EDIT_COUPON,
    DELETE_COUPON,
    GET_UNAPPROVED_DELIVERY_BOYS_ADMIN,
    APPROVE_DELIVERY_BOY_ADMIN,
    CREATE_DELIVERY_BOY,
    ALL_DELIVERY_BOYS,
    CREATE_DELIVERY_BOY_ADMIN,
} from "../endpoint";

const getVendors = async (userData) => {
    try {
        const response = await apiGet(`${base_url}${GET_VENDORS}`, userData);
        return response;
    } catch (error) {
        throw error;
    }
};

const approveVendor = async (userData) => {
    try {
        const response = await apiPost(`${base_url}${APPROVE_VENDOR}`, userData);
        return response;
    } catch (error) {
        throw error;
    }
};
 
const getAllProducts = async (userData) => {
    try {
        const response = await apiGet(`${base_url}${GET_ALL_PRODUCTS}`, userData);
        return response;
    } catch (error) {
        throw error;
    }
};

const approveProducts = async (userData) => {
    try {
        const response = await apiPost(`${base_url}${APPROVE_PRODUCTS}`, userData);
        return response;
    } catch (error) {
        throw error;
    }
};

const getUnapprovedProducts = async () => {
    try {
        const response = await apiGet(`${base_url}${GET_UNAPPROVED_PRODUCTS}`);
        return response;
    } catch (error) {
        throw error;
    }
}

const getVendorProducts = async (value) => {
    try {
        const response = await apiGet(`${base_url}${GET_VENDOR_PRODUCTS}/${value}`);
        return response;
    } catch (error) {
        throw error;
    }
}

const getAccountDetails = async () => {
    try {
        const response = await apiGet(`${base_url}${GET_ACCOUNT_DETAILS}`);
        return response;
    } catch (error) {
        throw error;
    }
}

const createCoupon = async (data) => {
    try {
        const response = await apiPost(`${base_url}${CREATE_COUPON}`,data);
        return response;
    } catch (error) {
        throw error;
    }
}

const getCoupon = async () => {
    try {
        const response = await apiGet(`${base_url}${GET_COUPON}`);
        return response;
    } catch (error) {
        throw error;
    }
}

const editCoupon = async (data) => {
    try {
        const response = await apiPut(`${base_url}${EDIT_COUPON}`,data);
        return response;
    } catch (error) {
        throw error;
    }
}



const deleteCoupon = async (id) => {
    try {
      const response = await apiPut(`${base_url}${DELETE_COUPON}${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getUnapprovedDeliveryBoys = async () => {
    try {
      const response = await apiGet(`${base_url}${GET_UNAPPROVED_DELIVERY_BOYS_ADMIN}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const approveDeliveryBoy = async (data) => {
    try {
      const response = await apiPost(`${base_url}${APPROVE_DELIVERY_BOY_ADMIN}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const createDeliveryBoy = async (data) => {
    try {
      const response = await apiPost(`${base_url}${CREATE_DELIVERY_BOY_ADMIN}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const allDeliveryBoys = async () => {
    try {
      const response = await apiGet(`${base_url}${ALL_DELIVERY_BOYS}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
const adminDataService ={
    getVendors,
    approveVendor,
    getAllProducts,
    approveProducts,
    getUnapprovedProducts,
    getVendorProducts,
    getAccountDetails,
    createCoupon,
    getCoupon,
    editCoupon,
    deleteCoupon,
    getUnapprovedDeliveryBoys,
    approveDeliveryBoy,
    createDeliveryBoy,
    allDeliveryBoys
};

export default adminDataService;