import { base_url } from "../config";
import {
  ADD_BANNER,
  ADD_CATEGORIES,
  APPROVE_DELIVERY_BOY,
  ASSIGN_DELIVERY_BOY_TO_ORDER,
  CREATE_DELIVERY_BOY,
  CREATE_PRODUCT,
  CUSTOMERS,
  DELETE_BANNER,
  DELETE_CATEGORY,
  DELETE_PRODUCT,
  EDIT_BANNER,
  GET_ALL_DELIVERY_BOYS,
  GET_BANNERS,
  GET_CATEGORIES,
  GET_DASHBOARD_INFO,
  GET_SUGGESTED_PRODUCT,
  GET_UNAPPROVED_DELIVERY_BOYS,
  GET_VENDOR_PROFILE,
  ORDERS_LIST,
  ORDER_DETAILS,
  PRODUCT_INFO,
  PRODUCT_LIST,
  SUGGESTION_PRODUCT,
  UPDATE_CATEGORY,
  UPDATE_ORDER,
  UPDATE_PRODUCT,
  UPLOAD_BANNER,
  UPLOAD_BULK_PRODUCT,
  UPLOAD_CATEGORY,
  UPLOAD_PRODUCT,
} from "../endpoint";
import { apiDelete, apiGet, apiPost, apiPut } from "../helpers/apiHelpers";

const getProducts = async (userData) => {
  try {
    const response = await apiGet(`${base_url}${PRODUCT_LIST}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

const getProductInfo = async (id) => {
  console.log("getProductInfo", id);
  try {
    const response = await apiGet(`${base_url}${PRODUCT_INFO}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const addProduct = async (product) => {
  console.log(product, "product incoming");
  try {
    const response = await apiPost(`${base_url}${CREATE_PRODUCT}`, product);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteProduct = async (id) => {
  try {
    const response = await apiPut(`${base_url}${DELETE_PRODUCT}${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateProduct = async (data,id) => {
  try {
    const response = await apiPut(
      `${base_url}${UPDATE_PRODUCT}${id}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// const uploadBulkProduct = async (file) => {
//   console.log(file,"file")

// let token = localStorage.getItem('jwtToken')
//   try {
//     const response = await axios.post(
//       'http://13.127.127.30:5000/products/create-bulk-products',
//       file,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         Authorization: token,

//         },
//       }
//     );

//     console.log('CSV data successfully uploaded to the API');
//     return response;
//   } catch (error) {
//     console.error('Error uploading CSV data to the API:', error);
//   }
// };

const uploadBulkProduct = async (products) => {
  try {
    const response = await apiPost(
      `${base_url}${UPLOAD_BULK_PRODUCT}`,
      products
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const productSuggestions = async (name) => {
  try {
    const response = await apiGet(
      `${base_url}${SUGGESTION_PRODUCT}?query=${name}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getSuggestedProducts = async (name) => {
  try {
    const response = await apiGet(
      `${base_url}${GET_SUGGESTED_PRODUCT}?search=${name}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getOrderList = async (name) => {
  try {
    const response = await apiGet(`${base_url}${ORDERS_LIST}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomers = async () => {
  try {
    const response = await apiGet(`${base_url}${CUSTOMERS}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateOrder = async (id, status) => {
  try {
    const response = await apiPut(`${base_url}${UPDATE_ORDER}/${id}`, status);
    return response;
  } catch (error) {
    throw error;
  }
};

const getBanners = async () => {
  try {
    const response = await apiGet(`${base_url}${GET_BANNERS}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getCategories = async () => {
  try {
    const response = await apiGet(`${base_url}${GET_CATEGORIES}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCategory = async (id) => {
  try {
    const response = await apiPut(`${base_url}${DELETE_CATEGORY}${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadBannerImage = async (file) => {
  try {
    const response = await apiPost(`${base_url}${UPLOAD_BANNER}`, file);
    return response;
  } catch (error) {
    throw error;
  }
};

const editBanner = async (data,id) => {
  try {
    const response = await apiPut(`${base_url}${EDIT_BANNER}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const addBanner = async (file) => {

  try {
    const response = await apiPost(`${base_url}${ADD_BANNER}`, file);
    return response;
  } catch (error) {
    throw error;
  }
}

const deleteBanner = async (id) => {

  try {
    const response = await apiPut(`${base_url}${DELETE_BANNER}${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

const uploadCategoryImage = async (file) => {
  try {
    const response = await apiPost(`${base_url}${UPLOAD_CATEGORY}`, file);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCategory = async (data,id) => {
  try {
    const response = await apiPut(`${base_url}${UPDATE_CATEGORY}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const addCategory = async (file) => {

  try {
    const response = await apiPost(`${base_url}${ADD_CATEGORIES}`, file);
    return response;
  } catch (error) {
    throw error;
  }
}

const uploadProductImage = async (file) => {
  try {
    const response = await apiPost(`${base_url}${UPLOAD_PRODUCT}`, file);
    return response;
  } catch (error) {
    throw error;
  }
};

const createDeliveryBoy = async (data) => {

  try {
    const response = await apiPost(`${base_url}${CREATE_DELIVERY_BOY}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

const getAllDeliveryBoys = async () => {

  try {
    const response = await apiGet(`${base_url}${GET_ALL_DELIVERY_BOYS}`);
    return response;
  } catch (error) {
    throw error;
  }
}

const getUnapprovedDeliveryBoys = async () =>{

  try {
    const response = await apiGet(`${base_url}${GET_UNAPPROVED_DELIVERY_BOYS}`);
    return response;
  } catch (error) {
    throw error;
  }
}

const approveDeliveryBoy = async (data) => {
  
try {
  const response = await apiPost(`${base_url}${APPROVE_DELIVERY_BOY}`, data);
  return response;
} catch (error) {
  throw error;
}
}

const getOrderDetails = async (id) => {

  try {
    const response = await apiGet(`${base_url}${ORDER_DETAILS}${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}


const assignDeliveryBoyToOrder = async (orderId, deliveryBoyId) => {

  try {
    const response = await apiPost(`${base_url}${ASSIGN_DELIVERY_BOY_TO_ORDER}/${orderId}`, { 
      deliveryBoyId
    });
    return response;
  } catch (error) {
    throw error;
  }
}

const getVendorProfile = async () => {
  
  try {
    const response = await apiGet(`${base_url}${GET_VENDOR_PROFILE}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getDashboardData = async () => {
  try {
    const response = await apiGet(`${base_url}${GET_DASHBOARD_INFO}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const dataService = {
  getProducts,
  getProductInfo,
  addProduct,
  deleteProduct,
  updateProduct,
  uploadBulkProduct,
  productSuggestions,
  getSuggestedProducts,
  getOrderList,
  getCustomers,
  updateOrder,
  getBanners,
  getCategories,
  uploadBannerImage,
  uploadCategoryImage,
  deleteCategory,
  uploadProductImage,
  editBanner,
  deleteBanner,
  addBanner,
  updateCategory,
  addCategory,
  createDeliveryBoy,
  getAllDeliveryBoys,
  getUnapprovedDeliveryBoys,
  approveDeliveryBoy,
  getOrderDetails,
  assignDeliveryBoyToOrder,
  getVendorProfile,
  getDashboardData
};

export default dataService;
