import React, { useEffect, useState } from "react";
import { Menu, Layout, theme, Drawer } from "antd";
import {
  AiOutlineCheckCircle,
  AiOutlineDashboard,
  AiOutlinePicture,
  AiOutlineSetting,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";
import { FaClipboardList, FaTruck } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url } from "../../network/api/config";
import { RiCoupon5Line } from "react-icons/ri";
import { version } from "../../App";
import { Box } from "@chakra-ui/react";
const { Header, Sider, Content } = Layout;

const SuperAdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedTime, setUpdatedTime] = useState("");
  const [loading, setLoading] = useState(false);

  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const jwtToken = localStorage.getItem("jwtToken");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const vendorId = localStorage.getItem("id");

  useEffect(() => {
    const updateCollapsedState = () => {
      const isMobile = window.innerWidth < 768;
      setCollapsed(isMobile);
    };

    updateCollapsedState();

    window.addEventListener("resize", updateCollapsedState);

    return () => {
      window.removeEventListener("resize", updateCollapsedState);
    };
  }, []);

  const chnageDateFormat = (date) => {
    const newDate = new Date(date).toLocaleDateString();
    const [month, day, year] = newDate.split("/");
    return [day, month, year].join("-");
  };
  const changeTimeFormat = (date) => {
    const newDate = new Date(date).toLocaleTimeString();
    return newDate;
  };
  console.log(chnageDateFormat(updatedDate), "chnageDateFormat");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${base_url}/vendors/notifications/${vendorId}`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      const notifications = response.data.data;

      const notificationTitles = notifications.map(
        (notification) => notification.title
      );
      const notificationDates = notifications.map(
        (notification) => notification.updatedAt
      );
      const notificationTimes = notifications.map(
        (notification) => notification.updatedAt
      );
      setApiData(notificationTitles);
      setUpdatedDate(notificationDates);
      setUpdatedTime(notificationTimes);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split("/");

  const targetKey = pathParts[2] || "";

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo" style={{ fontSize: "12px" }}>
              SA - Nirmalya
            </span>
            <span className="lg-logo">Super-Admin | Nirmalya</span>
          </h2>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={targetKey}
          onClick={({ key }) => {
            console.log(key, "key");
            navigate(key);
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
            },
            {
              key: "approve-request",
              icon: <AiOutlineCheckCircle className="fs-4" />,
              label: "Approve Request",
            },

            {
              key: "allProducts",
              icon: <AiOutlineShoppingCart className="fs-4" />,
              label: "All Products",
            },
            {
              key: "coupons",
              icon: <RiCoupon5Line className="fs-4" />,
              label: "Coupons",
            },
            // {
            //   key: "banners",
            //   icon: <AiOutlinePicture className="fs-4" />,
            //   label: "Banners",
            // },
            {
              key: "delivery",
              icon: <FaTruck className="fs-4" />,
              label: "Delivery",
            },
            {
              key: "profile",
              icon: <AiOutlineUser className="fs-4" />,
              label: "My Account",
            },
          ]}
        />
        <Box
          display={{ base: "none", md: "block" }}
          className="version-info"
          style={{
            position: "fixed",
            bottom: "0",
            padding: "16px",
            color: "white",
            textAlign: "center",
          }}
        >
          <h5>Version: {version}</h5>
        </Box>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="trigger">
            {/* {ismobile ? (
              <MenuUnfoldOutlined className="fs-4" />
            ) : (
              <MenuFoldOutlined className="fs-4" />
            )} */}
          </div>
          <div className="d-flex gap-4 align-items-center">
            <div
              className="position-relative"
              style={{ cursor: "pointer" }}
              onClick={showDrawer}
            >
              <IoIosNotifications className="fs-4" />
              {apiData.length > 0 && (
                <span className="badge bg-warning rounded-circle p-1 position-absolute">
                  {apiData.length}
                </span>
              )}
            </div>
            <Drawer
              title="Notification"
              placement="right"
              onClose={onClose}
              open={open}
              closable={false}
              style={{
                backgroundColor: "beige",
              }}
            >
              {apiData.map((notification, index) => (
                <div key={index} hoverable id="notification">
                  <h6>{notification}</h6>
                  <p style={{ fontSize: "12px" }}>
                    {chnageDateFormat(updatedDate[index])}&nbsp;&nbsp;&nbsp;
                    {changeTimeFormat(updatedTime[index])}
                  </p>
                </div>
              ))}
            </Drawer>

            <div className="d-flex gap-3 align-items-center dropdown">
              <div role="button" data-bs-toggle="dropdown">
                <img
                  width={32}
                  height={32}
                  src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  alt=""
                />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <h5 className="mb-0">{name}</h5>
                <p className="mb-0">{email}</p>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  {/* <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    View Profile
                  </Link> */}
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                    onClick={() => {
                      localStorage.clear();
                    }}
                  >
                    Signout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={1250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SuperAdminLayout;
