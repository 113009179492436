import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { toast } from "react-toastify";
import authService from "../network/api/services/authService";
import { useNavigate } from "react-router-dom";

const VendorSetPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");

    if (emailParam) {
      setEmail(emailParam);
    } else {
      console.error("Email not found in URL");
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
  
    const token = localStorage.getItem('jwtToken'); 
  
    if (!token) {
      setLoading(false);
      message.error("Authentication token not available. Please try again");
      return;
    }
  
    const payload = {
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      email: email,
    };
  
    console.log("Payload:", payload);
  
    try {
      const response = await authService.resetPassword(payload);
  
      console.log("Response Data:", response.data);
  
      message.success("Password updated successfully!");
    } catch (error) {
      console.error("Password update failed:", error);
      message.error("Password update failed. Please try again.");
    } finally {
      setLoading(false);
      form.resetFields();
      localStorage.removeItem('jwtToken');
      navigate(`/`);
    }
  };
  
  
  

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}
    >
      <div
        className="bg-white rounded p-5"
        style={{
          border: "1px solid #d9d9d9",
          maxWidth: "400px",
          width: "90%",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          className="title"
          style={{ fontFamily: "sans-serif", marginBottom: "20px" }}
        >
          Set Password
        </h2>

        <Form
          form={form}
          name="vendor_set_password"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: "Please enter your new password!" },
              { min: 6, message: "Password must be at least 6 characters!" },
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Set Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VendorSetPassword;
