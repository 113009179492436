import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = false;
  const id = localStorage.getItem("id") 

  
  if (id) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
