import React, { useState } from "react";
import "./BulkProducts.css";
import dataService from "../../network/api/services/dataService";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { Upload, Button, Input, Row, Col, Image } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { uploadProductImage } from "../../formatter/ImageConverter";
import { toast } from "react-toastify";
import csv from "../../../src/utils/csv.png";
import { saveAs } from "file-saver";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

function BulkProducts() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [categoryimageList, setCategoryImageList] = useState([]);

  const navigate = useNavigate();

  const headerArray = [
    "name",
    "description",
    "price",
    "category",
    "brand",
    "quantity",
    "sellingPrice",
    "costPrice",
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    displayCsvFile(file);
  };

  const handleImageUpload = async (rowIndex, fileList, name) => {
    try {
      if (name == "image") {
        const newImageList = [...imageList];

        for (const file of fileList) {
          const response = await uploadProductImage(file.originFileObj);
          console.log(response, "response -1");
          if (response.code == 200) {
            newImageList[rowIndex] = [
              ...(newImageList[rowIndex] || []),
              { url: response.data },
            ];
          } else {
            console.error("Error uploading image:", response.error);
          }
        }

        setImageList(newImageList);
      } else {
        const newcategoryimageList = [...categoryimageList];

        for (const file of fileList) {
          const response = await uploadProductImage(file.originFileObj);
          console.log(response, "response -1");
          if (response.code == 200) {
            newcategoryimageList[rowIndex] = [
              ...(newcategoryimageList[rowIndex] || []),
              { url: response.data },
            ];
          } else {
            console.error("Error uploading image:", response.error);
          }
        }

        setCategoryImageList(newcategoryimageList);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSampleCsvDownload = () => {
    const csvContent = headerArray.join(",") + "\r\n";
    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(csvBlob, "data.csv");
  };

  const displayCsvFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target.result;
        const lines = contents.split("\n");
        const data = lines.map((line) => line.split(","));
        console.log(data, "data");
        setTableData(data);
        setIsTableVisible(true);
        setEditedData(data.map((row) => [...row]));
        setImageList(Array(data.length - 1).fill([]));
        setCategoryImageList(Array(data.length - 1).fill([]));
      };

      reader.readAsText(file);
    }
  };

  const handleCellEdit = (rowIndex, colIndex, newValue) => {
    const updatedData = [...editedData];
    updatedData[rowIndex][colIndex] = newValue;
    setEditedData(updatedData);
  };

  const handleUploadSubmit = () => {
    if (selectedFile) {
      const csvContent = editedData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const editedFile = new File([blob], "edited_data.csv", {
        type: "text/csv",
      });

      Papa.parse(editedFile, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
          resultAsObject(result.data);
        },
        skipEmptyLines: true,
      });
    } else {
      toast.warning("Please select a file", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    console.log("handleUploadSubmit");
  };

  const resultAsObject = (data) => {
    console.log(data, "resultAsObject");
    const newData = {
      products: data.map((row, rowIndex) => {
        const images =
          imageList[rowIndex]?.map((file) => ({
            file,
          })) || [];
        const categoryImages =
          categoryimageList[rowIndex]?.map((file) => ({
            file,
          })) || [];
        return {
          ...row,
          images,
          categoryImages,
        };
      }),
    };

    uploadBulkProducts(newData);
  };

  const handleImageRemove = (rowIndex, fileIndex) => {
    const newImageList = [...imageList];
    newImageList[rowIndex] = newImageList[rowIndex].filter(
      (_, index) => index !== fileIndex
    );
    setImageList(newImageList);
  };

  const handleCategoryImageRemove = (rowIndex, fileIndex) => {
    const newIcategoryimageList = [...categoryimageList];
    newIcategoryimageList[rowIndex] = newIcategoryimageList[rowIndex].filter(
      (_, index) => index !== fileIndex
    );
    setCategoryImageList(newIcategoryimageList);
  };

  const uploadBulkProducts = async (data) => {
    console.log("Data to be sent to the server:", data);
    const response = await dataService.uploadBulkProduct(data);
    console.log(response, "response");
    const productDetails = response.data;
    if (response.code === 201) {
      toast.success("Products added successfully");
      navigate("/vendor/bulk-product-view", {
        state: { productDetails: productDetails },
      });
    } else {
      toast.warning("Warning: Something went wrong!");
    }
  };

  return (
    <div style={{ position: "relative" }} className="form-container">
      <h2 className="form-header">Add Bulk Products</h2>

      <form encType="multipart/form-data" id="csv-upload-form">
        <Button
          onClick={handleSampleCsvDownload}
          style={{ right: 0, border: "2px solid white", margin: "10px" }}
          type="primary"
        >
          Sample CSV
        </Button>
        <div className="form-group">
          <label htmlFor="csv-file" className="form-label">
            Select CSV File:
          </label>

          <input
            type="file"
            id="csv-file"
            className="form-input"
            accept=".csv"
            onChange={handleFileChange}
          />
        </div>
      </form>
      {isTableVisible && (
        <div className="table-container">
          <div className="table-wrapper">
            <div className="table-scroll">
              <div></div>
              <Table border={2}>
                <Thead>
                  {tableData[0].map((header, colIndex) => (
                    <Th key={colIndex}>{header}</Th>
                  ))}
                  <Th>Images</Th>
                  <Th>categoryImages</Th>
                </Thead>
                <Tbody>
                  {tableData
                    .slice(1, tableData.length - 1)
                    .map((row, rowIndex) => (
                      <Tr key={rowIndex}>
                        {row.map((cell, colIndex) => (
                          <Td key={colIndex}>
                            <Input
                              style={{ minWidth: "100px" }}
                              value={editedData[rowIndex + 1][colIndex]}
                              onChange={(e) =>
                                handleCellEdit(
                                  rowIndex + 1,
                                  colIndex,
                                  e.target.value
                                )
                              }
                            />
                          </Td>
                        ))}
                        <Td>
                          <Upload
                            beforeUpload={(file) => false}
                            onChange={({ fileList }) =>
                              handleImageUpload(rowIndex, fileList, "image")
                            }
                            fileList={imageList[rowIndex]}
                            showUploadList={false}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                          {imageList[rowIndex]?.length > 0 && (
                            <div>
                              {imageList[rowIndex].map((image, fileIndex) => (
                                <div
                                  key={fileIndex}
                                  className="image-container"
                                >
                                  <img
                                    src={image.url}
                                    alt={`Uploaded ${fileIndex + 1}`}
                                  />
                                  <Button
                                    className="delete-button"
                                    onClick={() =>
                                      handleImageRemove(rowIndex, fileIndex)
                                    }
                                    icon={<DeleteOutlined />}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </Td>
                        <Td>
                          <Upload
                            beforeUpload={(file) => false}
                            onChange={({ fileList }) =>
                              handleImageUpload(rowIndex, fileList, "category")
                            }
                            fileList={categoryimageList[rowIndex]}
                            showUploadList={false}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                          {categoryimageList[rowIndex]?.length > 0 && (
                            <div>
                              {categoryimageList[rowIndex].map(
                                (image, fileIndex) => (
                                  <div
                                    key={fileIndex}
                                    className="image-container"
                                  >
                                    <img
                                      src={image.url}
                                      alt={`Uploaded ${fileIndex + 1}`}
                                    />
                                    <Button
                                      className="delete-button"
                                      onClick={() =>
                                        handleCategoryImageRemove(
                                          rowIndex,
                                          fileIndex
                                        )
                                      }
                                      icon={<DeleteOutlined />}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </div>
      )}

      <button className="download-button" onClick={handleUploadSubmit}>
        Upload Products
      </button>
      {!isTableVisible && (
        <Row>
          <Col xs={24}>
            <h4 style={{ float: "left" }}>Sample csv : </h4>
            <Image src={csv} alt="Your Image Alt Text" />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default BulkProducts;
