import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import authService from "../network/api/services/authService";
import { toast } from "react-toastify";
const UserSubmitOTP = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Parse email parameter from URL
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");

    if (emailParam) {
      setEmail(emailParam);
    } else {
      console.error("Email not found in URL");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);

     const payload = {
        otp: values.otp,
        email: email,
      };

      console.log("Payload:", payload);

      const response = await authService.verifyUserOtp(payload);

        localStorage.setItem("jwtToken", response.data);

        message.success("OTP verified successfully!");
        form.resetFields();
        navigate(`/reset-user-password?email=${email}`);
      

    } catch (error) {
      message.error("Something Went Wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", backgroundColor: "#1a1a1a", color: "#fff" }}
    >
      <div
        className="bg-dark rounded p-5"
        style={{
          border: "1px solid #343a40",
          maxWidth: "500px",
          width: "95%",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(255, 255, 255, 0.1)", 
        }}
      >
        <h2
          className="title"
          style={{ fontFamily: "sans-serif", marginBottom: "20px", color: "#fff" }}
        >
     Submit OTP
        </h2>
        <p style={{ fontSize: "14px", marginBottom: "20px" }}>
          Enter the OTP sent to your email.
        </p>

        <Form
          form={form}
          name="submit_otp"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: "Please enter the OTP!" },
              { len: 5, message: "OTP must be 5 characters long" },
            ]}
          >
            <Input placeholder="OTP" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Submit OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserSubmitOTP;
