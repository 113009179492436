// import { toast } from 'react-toastify';


export const imageTypeValid = (name) =>{
  console.log(name,"name")
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
    const fileExtension = name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
        console.error('Invalid file type. Please upload a jpg, jpeg, png, or webp file.');
        // toast.error("Invalid file type.");
  
        return false;
      }else{
        return true;
      }
}