import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
  AiOutlineLogout,
  AiOutlineCloseSquare,
} from "react-icons/ai";
import { RiCouponLine } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import {
  FaClipboardList,
  FaBloggerB,
  FaTruckLoading,
  FaTruck,
  FaBars,
} from "react-icons/fa";
import { GiVerticalBanner } from "react-icons/gi";
import { SiBrandfolder } from "react-icons/si";
import { BiCategoryAlt } from "react-icons/bi";
import { Layout, Menu, theme, Drawer, Card } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./ProductCard.css";
import { base_url } from "../network/api/config";
import { version } from "../App";
import { Box } from "@chakra-ui/react";

const { Header, Sider, Content } = Layout;
const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedTime, setUpdatedTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [openmenu, setOpenmenu] = useState(false);
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("emailVendor");
  const jwtToken = localStorage.getItem("jwtToken");
  const showDrawer = () => {
    setOpen(true);
  };
  const close = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpenmenu(true);
  };
  const onClose = () => {
    setOpenmenu(false);
  };
  const vendorId = localStorage.getItem("id");
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${base_url}/vendors/notifications/${vendorId}`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      const notifications = response.data.data;

      const notificationTitles = notifications.map(
        (notification) => notification.title
      );
      const notificationDates = notifications.map(
        (notification) => notification.updatedAt
      );
      const notificationTimes = notifications.map(
        (notification) => notification.updatedAt
      );
      setApiData(notificationTitles);
      setUpdatedDate(notificationDates);
      setUpdatedTime(notificationTimes);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    fetchData();
  }, []);

  const chnageDateFormat = (date) => {
    const newDate = new Date(date).toLocaleDateString();
    const [month, day, year] = newDate.split("/");
    return [day, month, year].join("-");
  };
  const changeTimeFormat = (date) => {
    const newDate = new Date(date).toLocaleTimeString();
    return newDate;
  };
  console.log(chnageDateFormat(updatedDate), "chnageDateFormat");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    const updateCollapsedState = () => {
      const isMobile = window.innerWidth < 768;
      setCollapsed(isMobile);
    };

    updateCollapsedState();

    window.addEventListener("resize", updateCollapsedState);

    return () => {
      window.removeEventListener("resize", updateCollapsedState);
    };
  }, []);

  const location = useLocation();
  const { pathname } = location;
  console.log(pathname, "pathname");
  const pathParts = pathname.split("/");

  const targetKey = pathParts[2] || "";
  return (
    <Layout /* onContextMenu={(e) => e.preventDefault()} */>
      {!isMobile && (
        <Sider trigger={null}>
          <div className="logo">
            <h2 className="text-white fs-5 text-center py-3 mb-0">
              <span className="sm-logo">N</span>
              <span className="lg-logo">Nirmalya</span>
            </h2>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={targetKey}
            onClick={({ key }) => {
              console.log(key, "key");
              if (key == "signout") {
              } else {
                navigate(key);
              }
            }}
            items={[
              {
                key: "",
                icon: <AiOutlineDashboard className="fs-4" />,
                label: "Dashboard",
              },
              {
                key: "customers",
                icon: <AiOutlineUser className="fs-4" />,
                label: "Customers",
              },
              {
                key: "Catalog",
                icon: <AiOutlineShoppingCart className="fs-4" />,
                label: "Catalog",
                children: [
                  {
                    key: "add-bulk-product",
                    icon: <AiOutlineShoppingCart className="fs-4" />,
                    label: "Add Bulk Product",
                  },
                  {
                    key: "add-product",
                    icon: <AiOutlineShoppingCart className="fs-4" />,
                    label: "Add Product",
                  },
                  {
                    key: "list-product",
                    icon: <AiOutlineShoppingCart className="fs-4" />,
                    label: "Product List",
                  },
                ],
              },
              {
                key: "orders",
                icon: <FaClipboardList className="fs-4" />,
                label: "Orders",
              },
              // {
              //   key: "return",
              //   icon: <FaClipboardList className="fs-4" />,
              //   label: "Return",
              // },
              {
                key: "banners",
                icon: <GiVerticalBanner className="fs-4" />,
                label: "Banners",
              },
              {
                key: "categories",
                icon: <RiCouponLine className="fs-4" />,
                label: "Categories",
              },

              {
                key: "delivery",
                icon: <FaTruck className="fs-4" />,
                label: "Delivery",
              },
              {
                key: "profile",
                icon: <AiOutlineUser className="fs-4" />,
                label: "My Account",
              },
            ]}
          />
          <Box
            display={{ base: "none", md: "block" }}
            className="version-info"
            style={{
              position: "fixed",
              bottom: "0",
              padding: "16px",
              color: "white",
              textAlign: "center",
            }}
          >
            <h5>Version: {version}</h5>
          </Box>
        </Sider>
      )}

      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            {isMobile && (
              <FaBars size={24} className="fs-4" onClick={openDrawer} />
            )}
          </div>
          <div>
            <Drawer
              placement={"left"}
              closable={false}
              open={openmenu}
              onClose={onClose}
              headerStyle={{
                backgroundColor: "#1733E9",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
              }}
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Nirmalya
                </span>
              }
              bodyStyle={{
                backgroundColor: "#011529",
                padding: "0",
                margin: "0",
              }}
              width={300}
            >
              <div style={{ padding: "0", margin: "0" }}>
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={targetKey}
                  onClick={({ key }) => {
                    console.log(key, "key");
                    if (key == "signout") {
                    } else {
                      navigate(key);
                      onClose();
                    }
                  }}
                  items={[
                    {
                      key: "",
                      icon: <AiOutlineDashboard className="fs-4" />,
                      label: "Dashboard",
                    },
                    {
                      key: "customers",
                      icon: <AiOutlineUser className="fs-4" />,
                      label: "Customers",
                    },
                    {
                      key: "Catalog",
                      icon: <AiOutlineShoppingCart className="fs-4" />,
                      label: "Catalog",
                      children: [
                        {
                          key: "add-bulk-product",
                          icon: <AiOutlineShoppingCart className="fs-4" />,
                          label: "Add Bulk Product",
                        },
                        {
                          key: "add-product",
                          icon: <AiOutlineShoppingCart className="fs-4" />,
                          label: "Add Product",
                        },
                        {
                          key: "list-product",
                          icon: <AiOutlineShoppingCart className="fs-4" />,
                          label: "Product List",
                        },
                      ],
                    },
                    {
                      key: "orders",
                      icon: <FaClipboardList className="fs-4" />,
                      label: "Orders",
                    },
                    // {
                    //   key: "return",
                    //   icon: <FaClipboardList className="fs-4" />,
                    //   label: "Return",
                    // },
                    {
                      key: "banners",
                      icon: <GiVerticalBanner className="fs-4" />,
                      label: "Banners",
                    },
                    {
                      key: "categories",
                      icon: <RiCouponLine className="fs-4" />,
                      label: "Categories",
                    },

                    {
                      key: "delivery",
                      icon: <FaTruck className="fs-4" />,
                      label: "Delivery",
                    },
                    {
                      key: "profile",
                      icon: <AiOutlineUser className="fs-4" />,
                      label: "My Account",
                    },
                  ]}
                />
              </div>
            </Drawer>
          </div>

          <div className="d-flex gap-4 align-items-center">
            <div
              className="position-relative"
              style={{ cursor: "pointer" }}
              onClick={showDrawer}
            >
              <IoIosNotifications className="fs-4" />
              {apiData.length > 0 && (
                <span
                  className="badge bg-warning rounded-circle p-1 position-absolute"
                  style={{ top: "-12px", right: "-5px" }}
                >
                  {apiData.length}
                </span>
              )}
            </div>
            <Drawer
              title="Notification"
              placement="right"
              onClose={close}
              open={open}
              closable={true}
              style={{
                backgroundColor: "beige",
              }}
              width={300}
            >
              {apiData.map((notification, index) => (
                <div key={index} hoverable id="notification">
                  <h6>{notification}</h6>
                  <p style={{ fontSize: "12px" }}>
                    {chnageDateFormat(updatedDate[index])}&nbsp;&nbsp;&nbsp;
                    {changeTimeFormat(updatedTime[index])}
                  </p>
                </div>
              ))}
            </Drawer>

            <div className="d-flex gap-3 align-items-center dropdown">
              <div role="button" data-bs-toggle="dropdown">
                <img
                  width={32}
                  height={32}
                  src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  alt=""
                />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <h5 className="mb-0">{name}</h5>
                <p className="mb-0">{email}</p>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  {/* <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    View Profile
                  </Link> */}
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                    onClick={() => {
                      localStorage.removeItem("jwtToken");
                      localStorage.removeItem("id");
                      localStorage.removeItem("name");
                      localStorage.removeItem("emailVendor");
                      navigate("/");
                    }}
                  >
                    Signout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        <Content id="content">
          <ToastContainer
            position="top-right"
            autoClose={1250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
