import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import DataTable from "react-data-table-component";
import dataService from "../../network/api/services/dataService";

const dividerStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#1733E9",
  height: "2px",
  width: "100%",
};

const sectionStyle = {
  marginTop: "20px",
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "10px",
  maxHeight: "550px",
  overflowY: "auto",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
};

const Delivery = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [unapprovedDeliveryBoys, setUnapprovedDeliveryBoys] = useState([]);

  const columns = [
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },

    {
      name: "Approval Status",
      selector: "isApproved",
      cell: (row) =>
        row.isApproved ? (
          <span style={{ color: "green" }}>&#10004;</span> // Tick icon
        ) : (
          <span style={{ color: "red" }}>&#10008;</span> // Cross icon
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button type="primary" onClick={() => handleApprove(row)}>
          Approve
        </Button>
      ),
    },
  ];

  const columnsAll = [
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },

    {
      name: "Approval Status",
      selector: "isApproved",
      cell: (row) =>
        row.isApproved ? (
          <span style={{ color: "green" }}>&#10004;</span>
        ) : (
          <span style={{ color: "red" }}>&#10008;</span>
        ),
    },
    {
      name: "Action",
      cell: (row) =>
        row.type === "public" ? (
          ""
        ) : (
          <Button type="primary" onClick={() => handleApprove(row)}>
            Unapprove
          </Button>
        ),
    },
  ];

  const fetchUnapprovedDeliveryBoys = async () => {
    try {
      const response = await dataService.getUnapprovedDeliveryBoys();
      setUnapprovedDeliveryBoys(response.data);
    } catch (error) {
      console.error("Error fetching unapproved delivery boys:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUnapprovedDeliveryBoys();
        await fetchDeliveryBoys();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await dataService.createDeliveryBoy(values);

      if (response.success === true) {
        form.resetFields();
        setLoading(false);
        message.success("Delivery Boy created successfully");
        fetchDeliveryBoys();
        fetchUnapprovedDeliveryBoys();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response?.data?.error || "An error occurred");
    }
  };

  const fetchDeliveryBoys = async () => {
    try {
      const response = await dataService.getAllDeliveryBoys();
      setDeliveryBoys(response.data);
    } catch (error) {
      console.error("Error fetching delivery boys:", error);
    }
  };

  const handleApprove = async (row) => {
    try {
      const payload = {
        deliveryBoyId: row._id,
        isApproved: !row.isApproved,
      };

      const response = await dataService.approveDeliveryBoy(payload);

      message.success("Delivery Boy approval status updated successfully");
      fetchUnapprovedDeliveryBoys();
      fetchDeliveryBoys();
    } catch (error) {
      console.error("Error updating delivery boy approval status:", error);
      message.error(error.response?.data?.error || "An error occurred");
    }
  };

  return (
    <div>
      <h3>Manage Delivery Boys</h3>
      <div style={dividerStyle}></div>

      <div style={sectionStyle}>
        <h4>Create Delivery Boy</h4>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          {/* First Name */}
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input />
          </Form.Item>

          {/* Phone */}
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Email */}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Password */}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Delivery Boy"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <br />

      <div style={dividerStyle}></div>

      <div style={sectionStyle}>
        <h4>Approve Delivery Boy</h4>

        <DataTable columns={columns} data={unapprovedDeliveryBoys} />
      </div>
      <br />

      <div style={dividerStyle}></div>

      <div style={sectionStyle}>
        <h4>All Delivery Boys</h4>

        <DataTable
          columns={columnsAll}
          loading={loading}
          data={deliveryBoys.map((deliveryBoy) => ({
            firstName: deliveryBoy.profile.firstName,
            lastName: deliveryBoy.profile.lastName,
            email: deliveryBoy.email,
            isApproved: deliveryBoy.isApproved ? "true" : "false",
            _id: deliveryBoy._id,
            type: deliveryBoy.type,
          }))}
        />
      </div>
    </div>
  );
};

export default Delivery;
