import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import "../App.css"
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
    className: "column-class-sno",
  },
  {
    title: "Name",
    dataIndex: "name",
    className: "column-class-name",
  },
  {
    title: "Email",
    dataIndex: "email",
    className: "column-class-email",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    className: "column-class-mobile",
  },
  {
    title: "Status",
    dataIndex: "status",
    className: "column-class-status",
  },
  {
    title: "Action",
    dataIndex: "action",
    className: "column-class-action",
  },
];

const Enquiries = () => {
  const [open, setOpen] = useState(false);
  const [enqId, setenqId] = useState("");
  const [enquiries, setEnquiries] = useState([]); // State to hold the enquiries
  const [filteredEnquiries, setFilteredEnquiries] = useState([]); // State to hold filtered enquiries
  const [filterStatus, setFilterStatus] = useState("All"); // Initial filter status

  const dummyData = Array(15)
    .fill()
    .map((_, index) => ({
      key: index + 1,
      name: `Name ${index + 1}`,
      email: `email${index + 1}@example.com`,
      mobile: `123-456-${index + 1}`,
      status: index % 4 === 0 ? "Contacted" : "Submitted",
    }));

  useEffect(() => {
    setEnquiries(dummyData);
    setFilteredEnquiries(dummyData);
  }, []);

  const showModal = (enqId) => {
    setOpen(true);
    setenqId(enqId);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
    filterEnquiries(e.target.value);
  };

  const filterEnquiries = (status) => {
    if (status === "All") {
      setFilteredEnquiries(enquiries);
    } else {
      const filtered = enquiries.filter((enquiry) => enquiry.status === status);
      setFilteredEnquiries(filtered);
    }
  };

  return (
    <div>
      <h3 className="mb-4 title">Enquiries</h3>
      <div>
        <div className="filter">
          <label htmlFor="statusFilter">Filter by Status:</label>
          <select
            id="statusFilter"
            name="status"
            value={filterStatus}
            onChange={handleFilterChange}
          >
            <option value="All">All</option>
            <option value="Submitted">Submitted</option>
            <option value="Contacted">Contacted</option>
            <option value="In Progress">In Progress</option>
            <option value="Resolved">Resolved</option>
          </select>
        </div>
        <Table columns={columns} dataSource={filteredEnquiries} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          // Implement delete action here
        }}
        title="Are you sure you want to delete this enquiry?"
      />
    </div>
  );
};

export default Enquiries;
