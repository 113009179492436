import { useState } from "react";
import { Button, Input, Switch, Form, Upload, message } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dataService from "../../../network/api/services/dataService";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { imageUrlToFile } from "../../../formatter/ImageConverter";
import ImageDisplay from "../../../components/ImageDisplay";
import { toast } from "react-toastify";
import { imageTypeValid } from "../../../formatter/ValidImageType";

const AddAdminBanners = ({
  isDrawerVisible,
  setIsDrawerVisible,
  addedNewBanner,
  setaddedNewBanner,
  editedData,
  setFile,
  file,
  setEditedData,
  setbannerAction,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [bannerImage, setbannerImage] = useState("");
  const [bannerId, setbannerId] = useState("");

  const navigate = useNavigate();

  const [defaultFileList, setdefaultFileList] = useState([
    {
      url: "",
    },
  ]);

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dataService.uploadBannerImage(formData);
      if (response.data) {
        setbannerImage(response.data);
      } else {
        alert("Image upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSwitchChange = (checked) => {
    setVisible(checked);
  };

  const uploadBanner = async () => {
    setIsSubmitting(true);
    if (bannerImage) {
      try {
        const values = await form.validateFields();
        const data = {
          photo: bannerImage,
          visible,
          title,
        };
        let response;
        if (!editedData) {
          response = await dataService.addAdminBanner(data);
        } else {
          response = await dataService.editAdminBanner(data, bannerId);
        }
        if (response.success === true) {
          setaddedNewBanner(!addedNewBanner);
          setVisible(false);
          setbannerImage("");
          setTitle("");
          setIsDrawerVisible(false);
          setEditedData(null);
          setFile(null);
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please upload an image");
    }
    setIsSubmitting(false);
  };

  const handleUpload = (e) => {
    const selectedFile = e.target.files[0];
    const imageType = imageTypeValid(selectedFile.name);
    if (imageType) {
      uploadImage(selectedFile);
      setFile(selectedFile);
    } else {
      toast.error("Invalid file type.");
    }
  };

  const handleDelete = () => {
    setFile(null);
    setbannerImage("");
  };

  const convertImage = async () => {
    const imageFile = await imageUrlToFile(editedData.photo);
    setFile(imageFile);
  };

  useEffect(() => {
    if (editedData) {
      setdefaultFileList([{ url: editedData.photo }]);
      setTitle(editedData.title);
      setVisible(editedData.visible);
      setbannerId(editedData.orderId);
      setbannerImage(editedData.photo);
      setbannerAction("Update Banner");
      convertImage();
      form.setFieldsValue({
        title: editedData.title,
        visible: editedData.visible,
      });
    } else {
      setTitle("");
      setVisible(false);
      form.setFieldsValue({
        title: "",
        visible: false,
      });
    }
  }, [editedData, isDrawerVisible]);

  return (
    <Form form={form} layout="vertical">
      <ImageDisplay
        file={file}
        handleUpload={handleUpload}
        handleDelete={handleDelete}
      />

      <Form.Item
        label="Visible :"
        name="visible"
        valuePropName="checked"
        style={{ width: "50px" }}
      >
        <Switch
          defaultChecked={visible}
          checked={visible}
          onChange={handleSwitchChange}
        />
      </Form.Item>

      <Form.Item
        label="Title :"
        name="title"
        rules={[
          {
            required: true,
            message: "Please enter a title",
          },
          {
            min: 3,
            message: "Title must be at least 3 characters",
          },
        ]}
      >
        <Input
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          placeholder="Enter title"
        />
      </Form.Item>

      <Form.Item>
        <Button onClick={uploadBanner} type="primary" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddAdminBanners;
