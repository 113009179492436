import React, { useEffect, useState } from "react";
import dataService from "../../network/api/services/dataService";
import "./DashBoard.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Flex, Box, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { Column, Pie } from "@ant-design/plots";

const columns = [
  {
    name: "Order Code",
    selector: "code",
  },
  {
    name: "Total Price",
    selector: "totalPrice",
    format: (row) => `₹ ${row.totalPrice.toFixed(2)}`, // Formatting as currency
  },
  {
    name: "Status",
    selector: "status",
  },
  {
    name: "Ordered At",
    selector: "createdAt",
    format: (row) => moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss"), // Adjust date format
  },
];

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [dashboardData, setDashboardData] = useState({});

  const getDashboardData = async () => {
    try {
      const response = await dataService.getDashboardData();
      setDashboardData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const StatCard = ({ title, value, bgColor, textColor }) => {
    return (
      <WrapItem>
        <Box
          w={{ base: "300px", md: "250px" }}
          h="100px"
          bg={bgColor}
          borderRadius="md"
          p={4}
          boxShadow="lg"
          textAlign="center"
        >
          <Text fontSize="sm" fontWeight="bold" mb={2} color={textColor}>
            {title}
          </Text>
          <Text fontSize="xl" fontWeight="bold" color={textColor}>
            {value}
          </Text>
        </Box>
      </WrapItem>
    );
  };

  const getOrderList = async () => {
    try {
      const response = await dataService.getOrderList();
      const lastTenOrders = response.data.slice(-10);
      setOrders(lastTenOrders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const monthlyData = [
    { type: "Jan", sales: 16742 },
    { type: "Feb", sales: 23516 },
    { type: "Mar", sales: 29871 },
    { type: "Apr", sales: 36500 },
    { type: "May", sales: 48000 },
    { type: "Jun", sales: 38000 },
    { type: "Jul", sales: 42000 },
    { type: "Aug", sales: 49900 },
    { type: "Sep", sales: 78605 },
    { type: "Oct", sales: 65766 },
    { type: "Nov", sales: 30111 },
    { type: "Dec", sales: 23000 },
  ];

  const weeklyData = [
    { type: "Mon", sales: 5000 },
    { type: "Tue", sales: 6000 },
    { type: "Wed", sales: 7000 },
    { type: "Thu", sales: 8000 },
    { type: "Fri", sales: 9000 },
    { type: "Sat", sales: 10000 },
    { type: "Sun", sales: 11000 },
  ];

  const yearlyData = [
    { type: "2020", sales: 120000 },
    { type: "2021", sales: 150000 },
    { type: "2022", sales: 170000 },
    { type: "2023", sales: 200000 },
    { type: "2024", sales: 220000 },
  ];

  const [salesData, setSalesData] = useState(monthlyData);

  const handleSalesDataChange = (e) => {
    const value = e.target.value;
    if (value === "weekly") setSalesData(weeklyData);
    else if (value === "yearly") setSalesData(yearlyData);
    else setSalesData(monthlyData);
  };

  const salesConfig = {
    data: salesData,
    xField: "type",
    yField: "sales",
    color: ({ type }) => {
      const colors = {
        Jan: "#5B8FF9",
        Feb: "#5AD8A6",
        Mar: "#5D7092",
        Apr: "#F6BD16",
        May: "#6F5EF9",
        Jun: "#6DC8EC",
        Jul: "#9A7EFA",
        Aug: "#F08B74",
        Sep: "#E8684A",
        Oct: "#6F5EF9",
        Nov: "#7A91FA",
        Dec: "#7262FD",
        Mon: "#5B8FF9",
        Tue: "#5AD8A6",
        Wed: "#5D7092",
        Thu: "#F6BD16",
        Fri: "#6F5EF9",
        Sat: "#6DC8EC",
        Sun: "#9A7EFA",
        2020: "#5B8FF9",
        2021: "#5AD8A6",
        2022: "#5D7092",
        2023: "#F6BD16",
        2024: "#6F5EF9",
      };
      return colors[type] || "#7262FD";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
        fontWeight: "bold",
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: { alias: "Time" },
      sales: { alias: "Income" },
    },
  };

  const ongoingOrderDummyData = {
    totalUsers: 5,
    totalProducts: 10,
    highestOrderedProduct: { name: "Product A", quantity: 50 },
    users: [
      {
        name: "User 1",
        totalPrice: 1500,
        orders: [
          { productName: "Product A", quantity: 10 },
          { productName: "Product B", quantity: 5 },
        ],
      },
      {
        name: "User 2",
        totalPrice: 2000,
        orders: [
          { productName: "Product A", quantity: 20 },
          { productName: "Product C", quantity: 10 },
        ],
      },
      {
        name: "User 3",
        totalPrice: 1000,
        orders: [
          { productName: "Product B", quantity: 10 },
          { productName: "Product D", quantity: 5 },
        ],
      },
      {
        name: "User 4",
        totalPrice: 2500,
        orders: [
          { productName: "Product A", quantity: 15 },
          { productName: "Product E", quantity: 10 },
        ],
      },
      {
        name: "User 5",
        totalPrice: 1800,
        orders: [
          { productName: "Product C", quantity: 10 },
          { productName: "Product D", quantity: 8 },
        ],
      },
    ],
  };

  const ongoingOrderUsersData = ongoingOrderDummyData.users.map((user) => ({
    type: user.name,
    sales: user.totalPrice,
  }));

  const ongoingOrderProductsData = ongoingOrderDummyData.users.flatMap((user) =>
    user.orders.map((order) => ({
      type: order.productName,
      value: order.quantity,
    }))
  );

  const ongoingOrderUsersConfig = {
    data: ongoingOrderUsersData,
    angleField: "sales",
    colorField: "type",
    radius: 1,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };

  const ongoingOrderProductsConfig = {
    data: ongoingOrderProductsData,
    xField: "type",
    yField: "value",
    seriesField: "type",
    color: "#5B8FF9",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
        fontWeight: "bold",
      },
    },
  };

  return (
    <div>
      <Box>
        <Flex direction="column">
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Dashboard
          </Text>
          <Wrap spacing="4" justify="center">
            <StatCard
              title="Total Orders"
              value={dashboardData?.totalOrders}
              bgColor="#ffcccc"
              textColor="#cc3333"
            />
           
            
            <StatCard
              title="Total Products"
              value={dashboardData?.totalProducts}
              bgColor="#ccffff"
              textColor="#3399ff"
            />
            <StatCard
              title="Total Category"
              value={dashboardData?.totalCategory}
              bgColor="#9ad0ff"
              textColor="#0066cc"
            />
           
            <StatCard
              title="Rejected Orders"
              value={dashboardData?.rejectedOrders}
              bgColor="#e6e6ff"
              textColor="#6666ff"
            />
          </Wrap>
          <div>
            <Text fontSize="xl" fontWeight="bold" mb={4} mt={4}>
              Recent Orders
            </Text>
            <DataTable columns={columns} data={orders} />
          </div>
          {/* <div>
            <Text fontSize="xl" fontWeight="bold" mb={5} mt={5}>
              Sales
            </Text>
            <select onChange={handleSalesDataChange} defaultValue="monthly">
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
            <Column {...salesConfig} />
          </div>
          <div>
            <Text fontSize="xl" fontWeight="bold" mb={5} mt={5}>
              Ongoing Vendor Order Details
            </Text>
            <Pie {...ongoingOrderUsersConfig} />
          </div> */}
          {/* <Box mt={5}>
            <Text fontSize="xl" fontWeight="bold" mb={5} mt={5}>
              Product Wise 
            </Text>
            <Column {...ongoingOrderProductsConfig} />
          </Box> */}
        </Flex>
      </Box>
    </div>
  );
};

export default Dashboard;
