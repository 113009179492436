import React, { useEffect, useState } from "react";
import BackDropLoader from "../../components/BackDropLoader";
import DataTable from "react-data-table-component";
import axios from "axios";
import { base_url } from "../../network/api/config";
import { Select, message } from "antd";
import adminDataService from "../../network/api/services/AdminDataServices";
const { Option } = Select;

const AllProducts = () => {
  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      setIsRefreshLoading(true);
      try {
        const response = await adminDataService.getAllProducts();
        setProductList(response.data);
        setIsRefreshLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setIsRefreshLoading(false);
      }
    };

    const fetchVendors = async () => {
      try {
        const response = await adminDataService.getVendors();
        setVendors(response.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchProducts();
    fetchVendors();
  }, []);

  useEffect(() => {
    const filteredList = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sellingPrice
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        product.price
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        product.quantity
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredProductList(filteredList);
  }, [searchTerm, productList]);

  const handleVendorChange = async (value, option) => {
    setIsRefreshLoading(true);
    try {
      const response = await adminDataService.getVendorProducts(value);
      setProductList(response.data);
      setSelectedVendor(option);
      setIsRefreshLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setTimeout(() => {
        setIsRefreshLoading(false);
      }, 500);
    }
  };

  const columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    // { name: "Product ID", selector: (row) => row._id },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    { name: "Brand", selector: (row) => row.brand },
    { name: "Price", selector: (row) => row.price, sortable: true },
    {
      name: "SellingPrice",
      selector: (row) => row.sellingPrice,
      sortable: true,
    },
    { name: "Quantity", selector: (row) => row.quantity },
  ];

  return (
    <div>
      {isRefreshLoading && <BackDropLoader />}
      <h3 className="mb-4 title">All Products</h3>
      <div>
        <Select
          placeholder="Select Vendor"
          onChange={handleVendorChange}
          style={{ width: 200, marginBottom: 16 }}
        >
          <Option value="">All Vendors</Option>
          {vendors.map((vendor) => (
            <Option key={vendor._id} value={vendor._id}>
              {vendor.firstName} {vendor.lastName}
            </Option>
          ))}
        </Select>

        <DataTable
          columns={columns}
          data={filteredProductList}
          pagination
          paginationPerPage={30}
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search"
              className="input"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          }
          subHeaderAlign="right"
          noHeader
          highlightOnHover
          striped
          paginationResetDefaultPage={false}
          subHeaderWrap
          persistTableHeader
        />
      </div>
    </div>
  );
};

export default AllProducts;
