import React, { useState } from "react";
import { Steps, Form, Input, Upload, message, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
// import Api from "../lib/services/api";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { base_url } from "../network/api/config";
import Map from "../components/Map";
import { toast } from "react-toastify";

const { Step } = Steps;

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dob: "",
    bussinessName: "",
    bussinessType: "",
    bussinessAddress: "",
    bussinessDescription: "",
    bussinessWebsite: "",
    gstNumber: "",
    mobileNumber: "",
    longitude: "",
    latitude: "",
  });

  const handleLocationChange = (newLocation) => {
    console.log(newLocation, "newLocation");
    setUserData((prevUserData) => ({
      ...prevUserData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    }));
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const navigate = useNavigate();
  console.log(userData, "userData");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${base_url}/vendors/sign-up`,
        userData
      );

      if (response.status === 201) {
        console.log(response.data, "API response");
        notification.success({
          message: response.data.message,
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error during signup:", error);
      console.log(error.response.data.message);
      if (error.response) {
        notification.error({
          message: error.response.data.message,
        });
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "#f5f8ff",
      }}
    >
      <div
        className="py-5"
        style={{
          background: "#fff",
          maxWidth: "650px",
          margin: "0 auto",
          width: "100%",
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          padding: "2%",
          borderRadius: "10px",
          textAlign: "center",
          border: "1px solid black",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
      >
        <Steps size="default" current={currentStep}>
          <Step title="Personal Details" />
          <Step title="Business details" />
          {/* <Step title="Upload Documents" /> */}
        </Steps>

        <Form name="signupForm" layout="vertical">
          {currentStep === 0 && (
            <>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input
                  placeholder="Enter your first name"
                  onChange={handleChange}
                  value={userData.firstName}
                  name="firstName"
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input
                  placeholder="Enter your last name"
                  onChange={handleChange}
                  value={userData.lastName}
                  name="lastName"
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  onChange={handleChange}
                  value={userData.email}
                  name="email"
                />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your phone number"
                  onChange={handleChange}
                  value={userData.mobileNumber}
                  name="mobileNumber"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters long",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  onChange={handleChange}
                  value={userData.password}
                  name="password"
                />
              </Form.Item>

              <Form.Item
                label="Date of Birth"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "Please input your date of birth!",
                  },
                ]}
              >
                <Input
                  type="date"
                  placeholder="Enter your date of birth"
                  onChange={handleChange}
                  value={userData.dob}
                  name="dob"
                />
              </Form.Item>

              <Form.Item label="Select The location">
                <div style={{ width: "100%", padding: "10px" }}>
                  <Map onLocationChange={handleLocationChange} />
                </div>
              </Form.Item>

              <button
                type="button"
                className="btn btn-primary"
                onClick={nextStep}
              >
                Next
              </button>
            </>
          )}

          {currentStep === 1 && (
            <>
              <Form.Item
                label="Business Name"
                name="bussinessName"
                rules={[
                  {
                    required: true,
                    message: "Please input your business name!",
                  },
                ]}
              >
                <Input
                  onChange={handleChange}
                  value={userData.bussinessName}
                  name="bussinessName"
                />
              </Form.Item>

              <Form.Item
                label="Bussiness Type"
                name="bussinessType"
                rules={[
                  {
                    required: true,
                    message: "Please input your business type!",
                  },
                ]}
              >
                <Input
                  onChange={handleChange}
                  value={userData.bussinessType}
                  name="bussinessType"
                />
              </Form.Item>

              <Form.Item
                label="GST Number"
                name="gstNumber"
                rules={[
                  { required: true, message: "Please input your GST number!" },
                ]}
              >
                <Input
                  onChange={handleChange}
                  value={userData.gstNumber}
                  name="gstNumber"
                />
              </Form.Item>

              <Form.Item
                label="Business Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your business address!",
                  },
                ]}
              >
                <Input
                  onChange={handleChange}
                  value={userData.bussinessAddress}
                  name="bussinessAddress"
                />
              </Form.Item>

              <Form.Item
                label="Business Description"
                rules={[
                  {
                    required: true,
                    message: "Please input your business description!",
                  },
                ]}
              >
                <Input
                  onChange={handleChange}
                  value={userData.bussinessDescription}
                  name="bussinessDescription"
                />
              </Form.Item>

              <Form.Item label="Business Website">
                <Input
                  onChange={handleChange}
                  value={userData.bussinessWebsite}
                  name="bussinessWebsite"
                />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  class="btn btn-primary mb-3"
                  type="default"
                  onClick={prevStep}
                >
                  Previous
                </button>
                <button
                  type="default"
                  class="btn btn-primary mb-3"
                  // onClick={nextStep}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          )}

          {/* {currentStep === 2 && (
            <>

              <Form.Item
                label="Upload PAN Card"
                name="panCard"
                rules={[
                  { required: true, message: "Please upload your PAN card!" },
                ]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                   
                >
                  <button type="button" class="btn btn-primary" icon={<UploadOutlined />}>Upload PAN Card</button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Upload Aadhar Card"
                name="aadharCard"
                rules={[
                  {
                    required: true,
                    message: "Please upload your Aadhar card!",
                  },
                ]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                   
                >
                  <button className="btn btn-primary" icon={<UploadOutlined />}>Upload Aadhar Card</button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Upload GST Certificate"
                name="gstCertificate"
                rules={[
                  {
                    required: true,
                    message: "Please upload your GST certificate!",
                  },
                ]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                   
                >
                  <button className="btn btn-primary" icon={<UploadOutlined />}>Upload GST Certificate</button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Upload Proof of Address"
                name="proofOfAddress"
                rules={[
                  {
                    required: true,
                    message: "Please upload your proof of address!",
                  },
                ]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                   
                >
                  <button className="btn btn-primary" icon={<UploadOutlined />}>Upload Proof of Address</button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Upload Proof of Ownership"
                name="proofOfOwnership"
                rules={[
                  {
                    required: true,
                    message: "Please upload your proof of ownership!",
                  },
                ]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                   
                >
                  <button className="btn btn-primary" icon={<UploadOutlined />}>Upload Proof of Ownership</button>
                </Upload>
              </Form.Item>

                <div
                style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}
                >
                <button class="btn btn-primary" onClick={prevStep}>
                Previous
              </button>
              <button class="btn btn-primary" htmlType="submit">
                Submit
              </button>
                </div>
            </>
          )} */}
        </Form>
        <div className="mt-5">
          Already have an account?{" "}
          <Link className="text-primary" to="/">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
