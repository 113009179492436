import React, { useEffect, useState } from "react";
import { Button } from "antd";
import DataTable from "react-data-table-component";
import BackDropLoader from "../../components/BackDropLoader";
import adminDataService from "../../network/api/services/AdminDataServices";

const ApproveRequests = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const columns = [

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "150px",
     
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.active,
      sortable: true,
      cell: (row) => (row.active ? "Yes" : "No"),
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Cost Price",
      selector: (row) => row.costPrice,
      sortable: true,
    },
    {
      name: "Selling Price",
      selector: (row) => row.sellingPrice,
      sortable: true,
    },
    {
      name: "Is Approved",
      selector: (row) => row.isApproved,
      sortable: true,
      cell: (row) => (row.isApproved ? "Yes" : "No"),
    },
  ];
  

  const fetchData = async () => {
    setIsRefreshLoading(true);
    try {
      const response = await adminDataService.getUnapprovedProducts();
      setData(response.data);
      setIsRefreshLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsRefreshLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproveClick = async () => {
    setIsRefreshLoading(true);

    if (selectedRowKeys.length === 0) {
      console.log("No selected rows.");
      return;
    }

    const payload = {
      productIds: selectedRowKeys,
    };

    try {
      const response = await adminDataService.approveProducts(payload);

      console.log("API Response:", response.data);
      setSelectedRowKeys([]);
      setIsRefreshLoading(false);
      fetchData();
    } catch (error) {
      console.error("Error approving products:", error);
      setIsRefreshLoading(false);
    }
  };

  return (
    <div>
      {isRefreshLoading && <BackDropLoader />}
      <h1>Approve Requests</h1>
      <Button
        onClick={handleApproveClick}
        disabled={selectedRowKeys.length === 0}
        style={{ marginBottom: "16px", width: "200px", right: "10px" }}
      >
        Approve Selected
      </Button>
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        selectableRowsVisibleOnly
        selectableRowsHighlight
        onSelectedRowsChange={({ selectedRows }) => {
          const keys = selectedRows.map((row) => row._id);
          setSelectedRowKeys(keys);
        }}
        style={{
          width: "90%",
          marginTop: "20px",
          padding: "20px",
          backgroundColor: "white",
          border: "1px solid lightgray",
          minWidth: "400px",
          margin: "auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "10px",
          overflowX: "scroll",
        }}
      />
    </div>
  );
};

export default ApproveRequests;
