import React, { useEffect, useState } from "react";
import { Button, Input, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../../network/api/config";
import { ADD_CATEGORIES, UPDATE_CATEGORY } from "../../network/api/endpoint";
import { uploadCategoryImage } from "../../formatter/ImageConverter";
import ImageDisplay from "../../components/ImageDisplay";
import dataService from "../../network/api/services/dataService";
import { imageTypeValid } from "../../formatter/ValidImageType";

const AddCategories = ({
  onClose,
  selectedCategory,
  file,
  setFile,
  setnewCategory,
  newCategory,
  setIsDrawerVisible,
  getCategories,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photo, setPhoto] = useState("");
  //   const [productImages, setproductImages] = useState([

  // ]);
  useEffect(() => {
    console.log(selectedCategory, "selectedCategory");
    if (selectedCategory) {
      form.setFieldsValue({
        name: selectedCategory.name,
        image: selectedCategory.image,
      });
      setPhoto(selectedCategory.image);
      // const imageData = {           uid: String(Date.now()),
      //   name: "productImage" || 'New Image',
      //   status: "productStatus" || 'done',
      //   url: selectedCategory.image }
      //   setproductImages( [imageData] );
    } else {
      form.resetFields();
      setPhoto("");
    }
  }, [selectedCategory, form]);

  // const customRequest = ({ file, onSuccess }) => {
  //   setTimeout(() => {
  //     onSuccess();
  //     uploadImage(file);
  //   }, 500);
  // };

  const handleDelete = () => {
    setFile(null);
    setPhoto("");
  };

  const handleUpload = (e) => {
    console.log(e.target.files[0], "e.target.files[0]");
    const selectedFile = e.target.files[0];
    // const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
    // const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    // if (!allowedExtensions.includes(fileExtension)) {
    //   console.error('Invalid file type. Please upload a jpg, jpeg, png, or webp file.');
    //   toast.error("Invalid file type.");

    //   return;
    // }
    const imageType = imageTypeValid(selectedFile.name);
    if (imageType) {
      uploadImage(selectedFile);
      setFile(selectedFile);
    } else {
      toast.error("Invalid file type.");
    }
  };

  // const handleRemove = (file) => {
  //   console.log(file,"file",productImages)
  //   const updatedFileList = productImages.filter((item) => item.uid !== file.uid);
  //   setproductImages(updatedFileList);
  //   message.success(`${file.name} file removed successfully`);
  // };

  const uploadImage = async (file) => {
    console.log(file, "file");
    try {
      if (file.status !== "error") {
        const data = await uploadCategoryImage(file);
        toast.success(data.message);
        setPhoto(data.data);
        // const imageData = {           uid: String(Date.now()),
        //   name: "productImage" || 'New Image',
        //   status: "productStatus" || 'done',
        //   url: data.data }
        //   setproductImages( [imageData] );
        console.log(data, "data");
      } else {
        message.error("Image upload failed");
      }
    } catch (error) {
      console.error("Error during image upload:", error);
      message.error("Image upload failed");
    }
  };

  // const uploadCategory = async () => {
  //   try {
  //     setIsSubmitting(true);

  //     const payload = {
  //       name: form.getFieldValue("name"),
  //       image: photo,
  //     };

  //     if (selectedCategory) {
  //       await axios.put(
  //         `${base_url}${UPDATE_CATEGORY}/${selectedCategory._id}`,
  //         payload,
  //         {
  //           headers: {
  //             Authorization: localStorage.getItem("jwtToken"),
  //           },
  //         }
  //       );
  //       toast.success("Category updated successfully");

  //       form.resetFields();
  //       setPhoto("");
  //       onClose();
  //       setnewCategory(!newCategory)
  //       selectedCategory(null)
  //       setFile(null)
  //     } else {
  //       await axios.post(`${base_url}${ADD_CATEGORIES}`, payload, {
  //         headers: {
  //           Authorization: localStorage.getItem("jwtToken"),
  //         },
  //       });
  //       toast.success("Category added successfully");
  //     }

  //     form.resetFields();
  //     setPhoto("");
  //     onClose();
  //     setnewCategory(!newCategory)
  //     selectedCategory(null)
  //     setFile(null)
  //   } catch (error) {
  //     console.error("Error during category upload:", error);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const uploadCategory = async () => {
    const data = {
      name: form.getFieldValue("name"),
      image: photo,
    };

    console.log(data, "data", selectedCategory);

    if (photo) {
      try {
        const values = await form.validateFields();
        // const { title } = values;
        console.log(values, "values");
        console.log("uploadBanner uploadBanner");

        let response;
        if (!selectedCategory) {
          console.log(typeof editedData == null, "if");
          response = await dataService.addCategory(data);
          console.log(response, "add banner response");
        } else {
          console.log(typeof editedData == null, "else");
          response = await dataService.updateCategory(
            data,
            selectedCategory.key
          );
          console.log(response, "edit banner response");
        }

        console.log(response, "banner response");
        if (response.success === true) {
          toast.success("Category added successfully");
          setIsDrawerVisible(false);
          getCategories();
          setFile(null);
          form.setFieldsValue({
            name: "",
          });
          setPhoto("");
        } else {
          alert("something went wrong");
        }
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please upload image");
    }
  };

  // Cleanup function when the component unmounts
  useEffect(() => {
    return () => {
      form.resetFields();
      setPhoto("");
    };
  }, [form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please provide the name" }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      {/* <Form.Item
        label="Image"
        name="photo"
        // valuePropName="fileList"
        // getValueFromEvent={(e) => e && e.fileList}
        rules={[{ required: true, message: "Please upload a image" }]}
      >
        <Upload
          customRequest={customRequest}
          maxCount={1}
          listType="picture"
          accept="image/*"
          fileList={productImages}
          onRemove={handleRemove}

        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item> */}
      <Form.Item>
        <ImageDisplay
          file={file}
          handleDelete={handleDelete}
          handleUpload={handleUpload}
        />
      </Form.Item>

      <Form.Item>
        <Button onClick={uploadCategory} type="primary" loading={isSubmitting}>
          {selectedCategory ? "Update" : "Add"} Category
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCategories;
