import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import dataService from "../network/api/services/dataService";
import BackDropLoader from "../components/BackDropLoader";
import { Box, Flex, Text } from "@chakra-ui/react";

function generateCSVData(data) {
  const csvData = [];
  csvData.push(["Order ID", "Product Name", "Amount", "Order Date", "Status"]);

  data.forEach((order) => {
    csvData.push([
      order._id || "",
      order.products[0].name || "",
      order.products[0].sellPrice || "",
      new Date(order.createdAt).toLocaleString(),
      order.status || "",
    ]);
  });

  return Papa.unparse(csvData);
}

const Orders = () => {
  const [orderList, setOrderList] = useState([]);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const navigate = useNavigate();

  const handleExportToCSV = () => {
    const csvData = generateCSVData(orderList);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "orders.csv");
    link.click();
  };

  const columns = [
    { name: "SL.", selector: (row) => row.sono, width: "80px" },

    { name: "Order ID", selector: "orderId" },
    { name: "Total Price", selector: "totalPrice" },
    { name: "Delivery Type", selector: "deliveryType" },
    { name: "Order Date", selector: "orderDate" },
    { name: "Status", selector: "status" },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#202124",
      },
    },
    rows: {
      style: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#556080",
        backgroundColor: "#fff",
        height: "80px",
        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      },
    },
  };


  const getOrderList = async () => {
    try {
      setIsRefreshLoading(true);
      const response = await dataService.getOrderList();
      setOrderList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshLoading(false);
    }
  };

  const handleViewOrder = (record) => {
    const orderId = record.orderId;
    navigate(`/vendor/order-details/${orderId}`);
  };

  const handleRowClicked = (row) => {
    handleViewOrder(row);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  let serialNumberCounter = 0;

  return (
    <div>
      {isRefreshLoading && <BackDropLoader />}

      <h3 className="mb-4 title">Orders</h3>

      <Button
        onClick={handleExportToCSV}
        type="primary"
        style={{ marginBottom: "20px" }}
      >
        Export to CSV
      </Button>
      <Box display={{ base: "none", md: "block" }}>
        <DataTable
          columns={columns}
          data={orderList.map((order) => ({
            sono: (serialNumberCounter += 1),
            orderId: order._id,
            totalPrice: order.totalPrice,
            deliveryType: order.deliveryType,
            orderDate: new Date(order.createdAt).toLocaleDateString(),
            status: order.status,
          }))}
          pagination
          customStyles={customStyles}
          onRowClicked={handleRowClicked}
        />
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        {orderList.map((order) => (
          <Box
            key={order._id}
            bg="white"
            shadow="md"
            borderRadius="md"
            p={3}
            mb={4}
            onClick={() => navigate(`/vendor/order-details/${order._id}`)}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={1}>
              <Text fontWeight="bold">Status: {order.status}</Text>
              <Text>{new Date(order.createdAt).toLocaleDateString()}</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Total Price: ₹{order.totalPrice}</Text>
              <Text>Delivery Type: {order.deliveryType}</Text>
            </Flex>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Orders;
