import React, { useEffect, useState } from "react";
import { Typography, Form, Input, Button, Divider } from "antd";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import animationData from "../../animation.json";
import axios from "axios";
import { base_url } from "../../network/api/config";
import adminDataService from "../../network/api/services/AdminDataServices";
const { Title } = Typography;

const SuperAdminAccount = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    adminDataService.getAccountDetails()
      .then((response) => {
        const data = response.data;

        form.setFieldsValue({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password, 
          mobileNumber: data.mobileNumber,
          address: data.address,
        });

        setInitialValues({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          mobileNumber: data.mobileNumber,
          address: data.address,
        });

        console.log("Initial values:", initialValues);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [form]);

  const handleSaveAccount = (values) => {
    console.log("Account details saved:", values);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "16px" }}>
      <Title level={2} style={{ textAlign: "center" }}>
        My Account
      </Title>

      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleSaveAccount}
      >
        <Form.Item label="First Name" name="firstName">
          <Input />
        </Form.Item>

        <Form.Item label="Last Name" name="lastName">
          <Input />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input type="email" />
        </Form.Item>

        {/* <Form.Item label="Password" name="password">
          <Input.Password />
        </Form.Item> */}

        <Form.Item label="Phone Number" name="mobileNumber">
          <Input type="tel" />
        </Form.Item>

        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>

        {/* <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Save Account
          </Button>
        </Form.Item> */}
      </Form>

      <Divider />

      <Modal isOpen={isModalOpen} onClose={closeModal} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Success!</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Lottie animationData={animationData} />
            <p>Your changes have been saved successfully!</p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SuperAdminAccount;
